import * as actionTypes from '../actions/actionTypes';

const initialState = {
    loading: false,
    downloadGroups: [],
    products: [],
    eula: null,
    retrieved: false
};

export default (state = initialState, action) => {
    switch (action.type) {
    case actionTypes.FETCH_SOFTWARE_INIT:
        return { ...state, loading: true };
    case actionTypes.FETCH_SOFTWARE_DONE:
        return { ...state, products: action.payload.products, downloadGroups: action.payload.downloadGroups, loading: false, error: null, retrieved: true };
    case actionTypes.FETCH_SOFTWARE_FAIL:
        return { ...state, products: [], downloadGroups: [], loading: false, error: action.payload, retrieved: false };
    case actionTypes.FETCH_MODAL_CONTENT_INIT:
        return { ...state, loading: true };
    case actionTypes.FETCH_MODAL_CONTENT_DONE:
        return { ...state, eula: action.payload, loading: false, error: null };
    case actionTypes.FETCH_MODAL_CONTENT_FAIL:
        return { ...state, eula: null, loading: false };
    case actionTypes.ACCEPT_EULA_DONE: {
        const tempState = { ...state };
        tempState.products.forEach(product => {
            if (product.id === action.payload) product.eula_accepted = true;
        });
        return { ...tempState };
    }
    default:
        return state;
    }
};
