import { combineReducers } from 'redux';

import assets from './state/assets/reducers';
import { LOGOUT_DONE } from './state/auth/actions/actionTypes';
import auth from './state/auth/reducers';
import dashboard from './state/dashboard/reducers';
import deviceDetails from './state/deviceDetails/reducers';
import licenses from './state/licenses/reducers';
import orderActivations from './state/orderActivations/reducers';
import pncmodal from './state/pncmodal/reducers';
import softwareDownloads from './state/softwareDownloads/reducers';
import userProfile from './state/userProfile/reducers';

const appReducer = combineReducers({
    auth,
    assets,
    dashboard,
    deviceDetails,
    licenses,
    orderActivations,
    pncmodal,
    softwareDownloads,
    userProfile
});

const rootReducer = (state, action) => {
    // on logout reset ALL state so it can be re-populated with defaults
    if (action.type === LOGOUT_DONE) {
        state = undefined;
    }

    return appReducer(state, action);
};

export default rootReducer;
