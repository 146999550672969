import PropTypes from 'prop-types';
import React from 'react';
import { Button } from 'react-bootstrap';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';

import { processLogoutRequest } from '../../state/auth/dispatchers';

class Logout extends React.Component {
    componentDidMount() {
        this.props.processLogoutRequest();
    }

    render() {
        return (
            <div className="sm-container centered">
                <p>You have logged out.</p>
                <Link to="/user/login">
                    <Button className="btn btn-primary">
                        Click here to log in again
                    </Button>
                </Link>
            </div>
        );
    }
}

const mapDispatchToProps = {
    processLogoutRequest
};

Logout.propTypes = {
    processLogoutRequest: PropTypes.func
};

export default connect(null, mapDispatchToProps)(Logout);
