import PropTypes from 'prop-types';
import React from 'react';
import { Button } from 'react-bootstrap';
import { connect } from 'react-redux';

import { updateBookmarks } from '../../../state/dashboard/dispatchers';

class Bookmark extends React.Component {
    toggleBookmarks(id, action, type) {
        const bookmarks = { ...this.props.bookmarks };
        if (action === 'add') {
            bookmarks[type].push(id);
        } else {
            bookmarks[type].splice(bookmarks[type].indexOf(id), 1);
        }
        this.props.updateBookmarks({ type, value: bookmarks[type] });
    }

    render() {
        const { bookmarks, id, type } = this.props;
        let buttonClass = 'pn-green white-bg';
        let onClickAction = 'add';

        if (bookmarks[type].includes(id)) {
            buttonClass = '';
            onClickAction = 'remove';
        }

        return (
            <Button className={ buttonClass } size='sm' onClick={() => this.toggleBookmarks(id, onClickAction, type)}>
                <i className="fa fa-star" title="Add to Dashboard"/>
            </Button>
        );
    }
}

const mapStateToProps = (state) => ({
    bookmarks: state.dashboard.bookmarks

});

const mapDispatchToProps = {
    updateBookmarks
};

Bookmark.propTypes = {
    id: PropTypes.number,
    type: PropTypes.string,
    bookmarks: PropTypes.object,
    updateBookmarks: PropTypes.func
};

export default connect(mapStateToProps, mapDispatchToProps)(Bookmark);
