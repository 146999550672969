import PropTypes from 'prop-types';
import React from 'react';
import { Link } from 'react-router-dom';

import permissionHelper from '../../utils/permission-helper';

import styles from './SideBar.module.css';

const SideBar = (props) => {
    const { user } = props;
    if (!user) return null;
    const hasAccess = permissionHelper(user);

    return (
        <div id={styles.sidebar}>
            <div>
                <Link to="/dashboard">
                    <i className="pn-green fa fa-icon fa-home"/>Dashboard
                </Link>
            </div>
            {hasAccess('licensePortal') &&
                <div>
                    <span><i className="pn-green fa fa-icon fa-gavel"/>License Portal</span>
                    <Link className={styles.submenu} to="/license/create">Create License</Link>
                    <Link className={styles.submenu} to="/license/archive">License History</Link>
                </div>
            }
            {hasAccess('licenseActivation') &&
                <div>
                    <Link to="/orders/activations">
                        <i className="pn-green fa fa-icon fa-circle-o-notch"/>Activations
                    </Link>
                </div>
            }
            {hasAccess('devices') &&
                <div>
                    <Link to="/devices">
                        <i className="pn-green fa fa-icon fa-server"/>Devices
                    </Link>
                </div>
            }
            {hasAccess('softwareDownloads') &&
                <div>
                    <span><i className="pn-green fa fa-icon fa-download"/>Downloads</span>
                    <Link className={styles.submenu} to="/software">Current</Link>
                    <Link className={styles.submenu} to="/software/archive">Archives</Link>
                </div>
            }
            <div>
                <Link to="/user/logout">
                    <i className="pn-green fa fa-icon fa-sign-out"/>Logout
                </Link>
            </div>
            <div>
                <a href="https://www.pluribusnetworks.com/support/">
                    <i className="pn-green fa fa-question-circle fa-icon" />Support center
                </a>
            </div>
        </div>
    );
};

SideBar.propTypes = {
    user: PropTypes.object
};

export default SideBar;
