import { ACCOUNT_UPDATE_DONE, PROFILE_SAVE_DONE } from '../../userProfile/actions/actionTypes';
import * as actionTypes from '../actions/actionTypes';

const initialState = {
    showAgreements: true,
    showWelcomeModal: true,
    user: null,
    loading: false,
    error: null
};

export default (state = initialState, action) => {
    switch (action.type) {
    case actionTypes.LOGIN_INIT:
        return { ...state, loading: true };
    case actionTypes.LOGIN_DONE: {
        localStorage.setItem('currentUser', JSON.stringify(action.payload));
        return { ...state, user: action.payload, loading: false, error: null, showAgreements: action.payload.show_agreements, showWelcomeModal: action.payload.show_welcome };
    }
    case actionTypes.LOGIN_FAIL:
        return { ...state, user: null, error: action.payload, loading: false };
    case actionTypes.LOGIN_FORM_RESET:
        return { ...state, error: null };
    case actionTypes.LOGOUT_INIT:
        return { ...state, loading: true };
    case actionTypes.LOGOUT_DONE:
        return { ...state, user: null, loading: false, error: null };
    case actionTypes.LOGOUT_FAIL:
        return { ...state, error: action.payload, loading: false };
    case actionTypes.EULA_DONE:
        return { ...state, showAgreements: false, error: null };
    case actionTypes.EULA_FAIL:
        return { ...state, error: action.payload };
    case actionTypes.AGREEMENTS_FETCH_INIT:
        return { ...state, loading: true };
    case actionTypes.AGREEMENTS_FETCH_DONE:
        return { ...state, loading: false, agreements: action.payload, error: null };
    case actionTypes.AGREEMENTS_FETCH_FAIL:
        return { ...state, loading: false, error: action.payload };
    case PROFILE_SAVE_DONE:
        // Change user's full name when profile info is updated
        return { ...state, user: { ...state.user, full_name: action.payload.full_name } };
    case ACCOUNT_UPDATE_DONE: {
        const currentUser = JSON.parse(localStorage.getItem('currentUser'));
        currentUser.show_welcome = false;
        localStorage.setItem('currentUser', JSON.stringify(currentUser));
        return { ...state, showWelcomeModal: false };
    }
    default:
        return state;
    }
};
