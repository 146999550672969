import * as actionTypes from '../actions/actionTypes';

const initialState = {
    hwpids: [],
    swpids: [],
    loading: false,
    retrieved: false
};

export default (state = initialState, action) => {
    switch (action.type) {
    case actionTypes.FETCH_LICENSE_FORMS_DONE:
        return { ...state, hwpids: action.payload.hwpids, swpids: action.payload.swpids, loading: false, retrieved: true };
    default:
        return state;
    }
};
