import axios from 'axios';

import { activateDeviceFailed, activateDeviceInitialized, activateDeviceSucceeded, deactivateDeviceFailed, deactivateDeviceInitialized, deactivateDeviceSucceeded, fetchOrderDetailsFailed, fetchOrderDetailsInitialized, fetchOrderDetailsSucceeded } from '../actions/actionCreators';

export const processFetchOrderDetailsRequest = () => {
    return dispatch => {
        dispatch(fetchOrderDetailsInitialized());

        axios.get('/api/orderDetails')
            .then(response => {
                if (response) dispatch(fetchOrderDetailsSucceeded(response.data));
                else {
                    dispatch(fetchOrderDetailsFailed(response.data));
                }
            })
            .catch(
                err => {
                    dispatch(fetchOrderDetailsFailed(!err.response
                        ? 'Network Error' : err.response.statusText));
                }
            );
    };
};

export const processActivateDeviceRequest = (activationData) => {
    return dispatch => {
        dispatch(activateDeviceInitialized());

        axios.post('/api/orderActivation', activationData)
            .then(response => {
                if (response.data && response.data.order_activations) {
                    response.data.order_activations.id = response.data.order_activations.activation_id;
                }
                dispatch(activateDeviceSucceeded(response.data));
            })
            .catch(
                err => {
                    if (err.response && err.response.data && err.response.data.message) {
                        dispatch(activateDeviceFailed(err.response.data.message));
                    } else {
                        dispatch(activateDeviceFailed(!err.response
                            ? 'Network Error' : err.response.statusText));
                    }
                }
            );
    };
};


export const processDeactivateDeviceRequest = (deactivationData) => {
    return dispatch => {
        dispatch(deactivateDeviceInitialized());

        axios.post('/api/orderActivations/deactivateDevice', deactivationData)
            .then(response => {
                dispatch(deactivateDeviceSucceeded(deactivationData));
            })
            .catch(
                err => {
                    if (err.response && err.response.data && err.response.data.message) {
                        dispatch(deactivateDeviceFailed(err.response.data.message));
                    } else {
                        dispatch(deactivateDeviceFailed(!err.response
                            ? 'Network Error' : err.response.statusText));
                    }
                }
            );
    };
};
