import { BOOKMARKS_UPDATED, FETCH_DASHBOARD_DONE, FETCH_DASHBOARD_FAIL, FETCH_DASHBOARD_INIT } from '../actions/actionTypes';

const initialState = {
    loading: false,
    bookmarks: {
        software: [],
        devices: []
    },
    expiringLicenses: [],
    error: null,
    retrieved: false
};

export default (state = initialState, action) => {
    switch (action.type) {
    case FETCH_DASHBOARD_INIT:
        return { ...state, loading: true };
    case FETCH_DASHBOARD_DONE: {
        const software = action.payload.bookmarks.software || [];
        const devices = action.payload.bookmarks.devices || [];
        return { ...state, bookmarks: { software, devices }, expiringLicenses: action.payload.expiring_licenses, loading: false, error: null, retrieved: true };
    }
    case FETCH_DASHBOARD_FAIL:
        return { ...state, bookmarks: { software: [], devices: [] }, expiringLicenses: [], loading: false, error: action.payload, retrieved: false };
    case BOOKMARKS_UPDATED: {
        const updatedBookmarks = { ...state.bookmarks };
        updatedBookmarks[action.payload.type] = action.payload.value;
        return { ...state, bookmarks: updatedBookmarks };
    }
    default:
        return state;
    }
};
