import axios from 'axios';

import log from '../../../utils/logger';
import { fetchAssetsDeferred, fetchAssetsFailed, fetchAssetsInitialized, fetchAssetsSucceeded } from '../actions/actionCreators';

export const processFetchAssetsRequest = () => {
    return dispatch => {
        dispatch(fetchAssetsInitialized());
        axios.get('/api/assets')
            .then(response => {
                if (response.status === 200) {
                    dispatch(fetchAssetsSucceeded(response.data.assets));
                    log.debug('Cache updated on: ' + response.data.meta.last_update_complete);
                } else if (response.status === 202) {
                    log.debug('Async data request is being processed on the server');
                    dispatch(fetchAssetsDeferred());
                } else {
                    dispatch(fetchAssetsFailed(response.data.message));
                }
            }).catch(
                err => {
                    dispatch(fetchAssetsFailed(!err.response ? 'NetworkError' : err.response.statusText));
                }
            );
    };
};
