import React from 'react';
import NavBar from '../NavBar';

class NoMatch extends React.Component {
    render() {
        return (
            <>
                <NavBar />
                <div id="main">
                    <div style={{ marginTop: 80, marginLeft: 55 }}>
                        <span className='pn-red'>Error 404. Requested url does not exist.</span>
                        <br />
                        <span>Click <a href={`//${window.location.host}`}>here</a> to navigate to dashboard.</span>
                    </div>
                </div>
            </>
        );
    }
}

export default NoMatch;
