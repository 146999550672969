import axios from 'axios';

import { bookmarksUpdated, fetchDashboardFailed, fetchDashboardInitialized, fetchDashboardSucceeded } from '../actions/actionCreators';

export const processFetchDashboardRequest = () => {
    return dispatch => {
        dispatch(fetchDashboardInitialized());

        axios.get('/api/dashboard')
            .then(response => {
                if (response) dispatch(fetchDashboardSucceeded(response.data));
                else {
                    dispatch(fetchDashboardFailed(response.data));
                }
            })
            .catch(
                err => {
                    dispatch(fetchDashboardFailed(!err.response
                        ? 'Network Error' : err.response.statusText));
                }
            );
    };
};

export const updateBookmarks = (bookmarks) => {
    return dispatch => {
        axios.post('/api/dashboard/updateBookmark', bookmarks);
        dispatch(bookmarksUpdated(bookmarks));
    };
};
