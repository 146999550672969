import axios from 'axios';
import PropTypes from 'prop-types';
import React from 'react';
import { Button, Table } from 'react-bootstrap';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { connect } from 'react-redux';
import ReactRouterPropTypes from 'react-router-prop-types';
import { toast } from 'react-toastify';

import { processEulaAcceptanceRequest, processFetchModalContentRequest, processFetchSoftwareRequest } from '../../state/softwareDownloads/dispatchers';
import { triggerDownload } from '../../utils/downloader';
import log from '../../utils/logger';
import pncmodal from '../../utils/pncmodal';
import Bookmark from '../Common/Bookmark';

import styles from './SoftwareDownloads.module.css';

const sortFunction = (a, b) => (a.list_order < b.list_order) ? -1 : (a.list_order > b.list_order) ? 1 : 0;

class SoftwareDownloads extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            copied: null,
            eulaDisplayed: false,
            selectedDownloadType: null,
            selectedProduct: null
        };
    }

    componentDidMount() {
        if (!this.props.retrieved) this.props.processFetchSoftwareRequest();
    }

    downloadFile(downloadType, swPid, version) {
        const fileDownloadUrl = `/api/${downloadType}/${swPid}?version=${version}`;
        let errorMessage = 'Failed to access download server. Please contact support for help.';
        log.info('User attempting to download software package.', downloadType, swPid, version);
        const noteId = toast.info('Starting download...', { autoClose: 5000 });

        axios.head(fileDownloadUrl)
            .then(response => {
                if (response.status === 200) {
                    triggerDownload(fileDownloadUrl);
                } else {
                    toast.dismiss(noteId);
                    log.error('Failed to download software package.', response.statusText);
                    pncmodal.error(errorMessage, 'Failed to download package');
                }
            })
            .catch(error => {
                toast.dismiss(noteId);
                if (error.response.status === 451) {
                    errorMessage = 'Download is blocked, please contact Pluribus Networks support for further information.';
                }
                log.error('Failed to download software package.', error.response.statusText);
                pncmodal.error(errorMessage, 'Failed to download package');
            });
    }

    handleDownload(downloadType, product) {
        if (product.eula_accepted) {
            this.downloadFile(downloadType, product.sw_pid, product.version);
        } else {
            this.setState({ eulaDisplayed: true, selectedDownloadType: downloadType, selectedProduct: product });
            this.props.processFetchModalContentRequest(product.sw_pid);
        }
    }

    acceptEula() {
        const { selectedProduct, selectedDownloadType } = this.state;
        this.props.processEulaAcceptanceRequest(
            { version: selectedProduct.version, sw_pid: selectedProduct.sw_pid, id: selectedProduct.id }
        );
        this.setState({ eulaDisplayed: false });
        this.downloadFile(selectedDownloadType, selectedProduct.sw_pid, selectedProduct.version);
    }

    renderTableRows(displayedProducts) {
        const { bookmarks, bookmarkedOnly } = this.props;
        return displayedProducts.map(product => {
            if ((bookmarks && bookmarkedOnly && bookmarks.includes(product.id)) || !bookmarkedOnly) {
                return (
                    <React.Fragment key={product.id}>
                        <tr>
                            <td width='25%' rowSpan={product.platform_2 ? '2' : null} dangerouslySetInnerHTML={{ __html: product.name }}/>
                            <td width='10%' rowSpan={product.platform_2 ? '2' : null}>{product.version}</td>
                            <td width='10%'>{product.platform}</td>
                            <td width='25%' className={styles.checksumField}>
                                <div className={styles.md5}>{product.md5}</div>
                                <CopyToClipboard className='md5copy copy-button' text={product.md5}
                                                 title='Copy to Clipboard'
                                                 onCopy={() => this.setState({ copied: product.md5 })}>
                                    {
                                this.state.copied !== product.md5
                                    ? <Button size='sm'><i className='fa fa-copy'/></Button>
                                    : <Button size='sm' disabled><i className='fa fa-copy'/></Button>
                            }
                                </CopyToClipboard>

                            </td>
                            <td width='15%' className={styles.listButton}>
                                {
                            product.doc_url &&
                                <Button size='sm' className='space-right' onClick={() => this.handleDownload('download_doc', product)}>
                                    <i className='fa fa-file-pdf-o'/>
                                </Button>
                        }
                                {
                            product.release_notes &&
                                <Button size='sm' className='space-right' onClick={() => this.handleDownload('download_notes', product)}>
                                    <i className='fa fa-file-text-o'/>
                                </Button>
                        }
                            </td>
                            <td width='15%' className={styles.listButton}>
                                {
                            product.sw_pid &&
                                <Button size='sm' onClick={() => this.handleDownload('download_image1', product)}>
                                    <i className='fa fa-download space-right'/>Download
                                </Button>
                        }
                            </td>
                            <td>
                                <Bookmark id={product.id} type="software"/>
                            </td>
                        </tr>
                        {
                    product.platform_2
                        ? <tr>
                            <td width='10%'>{product.platform_2}</td>
                            <td width='25%' className={styles.checksumField}>
                                <div className='md5'>{product.md5_2}</div>
                                <CopyToClipboard className='md5copy copy-button' text={product.md5_2} onCopy={() => this.setState({ copied: product.md5_2 })}>
                                    {
                                        this.state.copied !== product.md5_2
                                            ? <Button size='sm'><i className='fa fa-copy'/></Button>
                                            : <Button size='sm' disabled><i className='fa fa-copy'/></Button>
                                    }
                                </CopyToClipboard>
                            </td>
                            <td width='15%' className={styles.listButton}>
                                {
                                    product.doc_url &&
                                        <Button size='sm' className='space-right' onClick={() => this.handleDownload('download_doc', product)}>
                                            <i className='fa fa-file-pdf-o'/>
                                        </Button>
                                }
                                {
                                    product.ease_notes &&
                                        <Button size='sm' className='space-right' onClick={() => this.handleDownload('download_notes', product)}>
                                            <i className='fa fa-file-text-o'/>
                                        </Button>
                                }
                            </td>
                            <td width='15%' className={styles.listButton}>
                                {
                                    product.sw_pid &&
                                        <Button size='sm' onClick={() => this.handleDownload('download_image2', product)}>
                                            <i className='fa fa-download space-right'/>Download
                                        </Button>
                                }
                            </td>
                            <td>
                                <Bookmark id={product.id} type="software"/>
                            </td>
                        </tr>
                        : null
                }
                    </React.Fragment>
                );
            }
            return null;
        });
    }

    renderProductsTable(groupId) {
        const { products, bookmarkedOnly, bookmarks } = this.props;
        let displayedProducts = products.filter(group => group.download_group_id === groupId).sort(sortFunction);
        if (bookmarks && bookmarkedOnly) displayedProducts = displayedProducts.filter(product => bookmarks.includes(product.id));
        if (!displayedProducts.length) return null;
        return (
            <Table bordered>
                <thead>
                    <tr>
                        <th>Name</th>
                        <th>Version</th>
                        <th>Platform</th>
                        <th>Checksum</th>
                        <th>Documentation</th>
                        <th>Download</th>
                        <th>&nbsp;</th>
                    </tr>
                </thead>
                <tbody>
                    { this.renderTableRows(displayedProducts) }
                </tbody>
            </Table>
        );
    }

    renderGroups(current = true) {
        const { downloadGroups } = this.props;
        const displayedGroups = downloadGroups.filter(group => current ? group.current_release : !group.current_release).sort(sortFunction);

        return displayedGroups.map(group => {
            const productTable = this.renderProductsTable(group.id);
            if (productTable) {
                return (
                    <React.Fragment key={group.id}>
                        <div>
                            <b>{group.name}</b>
                        </div>
                        <div dangerouslySetInnerHTML={{ __html: group.description }} />
                        { productTable }
                    </React.Fragment>
                );
            }
            return null;
        });
    }

    render() {
        const { showCurrent, eulaContent, bookmarkedOnly } = this.props;
        const title = showCurrent ? 'SOFTWARE' : 'ARCHIVES';
        const eulaTitle = 'End-User License Agreement (EULA)';
        return (
            <>
                <div className='left'>
                    { !bookmarkedOnly && <div className='pn-section-header pn-green' >{title}</div> }
                    <div>
                        {this.renderGroups(showCurrent)}
                    </div>
                </div>
                {this.state.eulaDisplayed && pncmodal.info(eulaContent, eulaTitle, [
                    {
                        label: 'Decline',
                        variant: 'secondary',
                        callback: () => this.setState({ eulaDisplayed: false })
                    },
                    {
                        label: 'Accept',
                        variant: 'primary',
                        callback: () => this.acceptEula()
                    }
                ])}
            </>
        );
    }
}


const mapStateToProps = (state) => ({
    downloadGroups: state.softwareDownloads.downloadGroups,
    products: state.softwareDownloads.products,
    loading: state.softwareDownloads.loading,
    eulaContent: state.softwareDownloads.eula,
    bookmarks: state.dashboard.bookmarks.software,
    retrieved: state.softwareDownloads.retrieved
});

const mapDispatchToProps = {
    processFetchSoftwareRequest,
    processFetchModalContentRequest,
    processEulaAcceptanceRequest
};

SoftwareDownloads.propTypes = {
    showCurrent: PropTypes.bool,
    bookmarks: PropTypes.array,
    bookmarkedOnly: PropTypes.bool,
    // when bookmarkedOnly props is set, only items with ids present in `bookmarks` will be shown.
    // This is useful when using this component as a part of other screen e.g. Dashboard
    retrieved: PropTypes.bool,
    downloadGroups: PropTypes.array,
    products: PropTypes.array,
    loading: PropTypes.bool,
    eulaContent: PropTypes.string,
    processFetchSoftwareRequest: PropTypes.func,
    processFetchModalContentRequest: PropTypes.func,
    processEulaAcceptanceRequest: PropTypes.func,
    history: ReactRouterPropTypes.history
};

export default connect(mapStateToProps, mapDispatchToProps)(SoftwareDownloads);
