import './index.css';

import axios from 'axios';
import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { applyMiddleware, compose, createStore } from 'redux';
import logger from 'redux-logger';
import thunk from 'redux-thunk';

import App from './containers/App';
import reducer from './rootReducer';
import * as serviceWorker from './serviceWorker';
import { loginSucceeded } from './state/auth/actions/authActions';

axios.defaults.xsrfCookieName = 'csrftoken';
axios.defaults.xsrfHeaderName = 'X-CSRFTOKEN';

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const middleware = [thunk];

if (process.env.NODE_ENV === 'development') {
    middleware.push(logger);
}

const store = createStore(reducer, composeEnhancers(
    applyMiddleware(...middleware)
));

const app = (
    <Provider store={store}>
        <App />
    </Provider>
);

const currentUser = localStorage.getItem('currentUser');
if (currentUser) {
    if (!window.location.href.includes('impersonate')) {
        store.dispatch(loginSucceeded(JSON.parse(currentUser)));
    }
}

ReactDOM.render(app, document.getElementById('root') || document.createElement('div'));

serviceWorker.unregister();

export { store };
