import React from 'react';
import { Button } from 'react-bootstrap';
import { Link } from 'react-router-dom';

const ExportUserData = () => {
    return (
        <>
            <div className="pn-section-header pn-color">Export Data</div>
            <p>
                You can export your personal data that is stored by Pluribus and save it as csv file.
            </p>
            <p>
                <Link to="/api/account/export" target="_blank" download>
                    <Button className="btn pn-bg-color pn-white">Export my personal data</Button>
                </Link>
            </p>
            <hr/>
        </>
    );
};

export default ExportUserData;
