import axios from 'axios';
import PropTypes from 'prop-types';
import React from 'react';
import { Button, Form } from 'react-bootstrap';
import { connect } from 'react-redux';

import { processFetchLicenseFormsRequest } from '../../state/licenses/dispatchers';
import log from '../../utils/logger';
import pncmodal from '../../utils/pncmodal';
import LicenseKeyModal from '../modals/LicenseKeyModal';

import styles from './LicenseCreate.module.css';

export class LicenseCreate extends React.Component {
    constructor(props) {
        super(props);
        this.initialState = {
            serial: '',
            isSerialValid: false,
            availableHardware: [],
            availableSoftware: [],
            selectedHw: 0,
            selectedSw: 0,
            isHwSelectorDisabled: true,
            isSwSelectorDisabled: true,
            isCreatingLicense: false,
            isLookingUpHardware: false,
            licenseKey: null
        };
        this.state = { ...this.initialState };
    }

    componentDidMount() {
        if (!this.props.retrieved) this.props.processFetchLicenseFormsRequest();
    }

    getDeviceDetails() {
        axios.get('/api/switches', {
            params: { serial: this.state.serial }
        }).then(response => {
            this.getAvailableHardware(response?.data?.switches[0]?.hwPid || undefined);
        }).catch(() => {
            this.getAvailableHardware(undefined);
        });
    }

    getAvailableHardware(hwPid) {
        let availableHardware = this.props.hwPids.filter(hw => hw.code === hwPid);
        if (!availableHardware.length) availableHardware = this.props.hwPids;
        this.setState({ availableHardware, isHwSelectorDisabled: false, isLookingUpHardware: false });
    }

    getAvailableSoftware(hwPid) {
        let availableSoftware;
        if (hwPid) {
            availableSoftware = this.props.swPids.filter(
                sw => !sw.deprecated &&
            (sw.hwPids.includes(hwPid) || (sw.upgrade && sw.upgradeFrom.includes(hwPid)))
            );
        } else {
            availableSoftware = this.props.swPids.filter(sw => !sw.deprecated);
        }
        this.setState({ availableSoftware, isSwSelectorDisabled: false });
    }


    createLicenseKey() {
        this.setState({ isCreatingLicense: true });
        const payload = {
            license:
            {
                serial: this.state.serial,
                code: this.state.selectedSw,
                timestamp: new Date().toLocaleString('sv') // YYYY-MM-DD HH:MM:SS
            }
        };
        axios.post('/api/licenses', payload
        ).then(response => {
            this.setState({ licenseKey: response.data.license.key });
        }).catch(error => {
            log.error('Failed to create license.', error);
            pncmodal.error(error, 'Failed to create license',
                [
                    { label: 'OK', value: 'ok', variant: 'danger', callback: () => this.setState(this.initialState) }
                ]);
        });
    }

    isValidSerial(serial) {
        const format = /^\d{4}\w{4}\d{5}$/; // to match SN format: YYWWLLCCUUUUX
        const expectedLength = 13;
        return serial.length === expectedLength && format.test(serial);
    }

    isValidUnumSerial(serial) {
        const format = /^[a-zA-Z0-9]{8}-[a-zA-Z0-9]{8}-[a-zA-Z0-9]{8}-[a-zA-Z0-9]{8}/;
        const expectedLength = 35;
        return serial.length === expectedLength && format.test(serial);
    }

    validateSerial() {
        if (this.isValidSerial(this.state.serial)) {
            this.setState({ isLookingUpHardware: true, isSerialValid: true });
            this.getDeviceDetails();
        } else if (this.isValidUnumSerial(this.state.serial)) {
            this.getAvailableSoftware(undefined);
            this.setState({ isSerialValid: true, isHwSelectorDisabled: true, isSwSelectorDisabled: false });
        } else {
            this.setState({ isSerialValid: false, isHwSelectorDisabled: true, isSwSelectorDisabled: true, selectedHw: 0, selectedSw: 0 });
        }
    }

    handleSerialInput(serial) {
        this.setState({ serial, selectedHw: 0, selectedSw: 0 }, () => this.validateSerial());
    }

    handleHwSelect(e) {
        this.setState({ selectedHw: e.target.value }, () => this.getAvailableSoftware(this.state.selectedHw));
    }

    handleSwSelect(e) {
        this.setState({ selectedSw: e.target.value });
    }

    renderOptions(collection) {
        return this.state[collection].map(item =>
            <option key={item.code} value={item.code}>[{item.code}] {item.description}</option>
        );
    }

    render() {
        const { serial, selectedHw, selectedSw, isSerialValid, isHwSelectorDisabled, isSwSelectorDisabled, isCreatingLicense, isLookingUpHardware, licenseKey } = this.state;
        return (
            <>
                <div className='pn-section-header left pn-green' >Create License</div>
                <Form noValidate>
                    <Form.Group >
                        <Form.Label className={styles.blockLabel}>1. Enter serial number</Form.Label>
                        <Form.Control isValid={isSerialValid} className="search-input" size='sm' type="text" value={serial || ''} onChange={(e) => this.handleSerialInput(e.target.value)}/>
                    </Form.Group>
                    <Form.Group >
                        <Form.Label className={styles.blockLabel}>2. Select hardware model</Form.Label>
                        <Form.Control as="select" value={selectedHw} onChange={e => this.handleHwSelect(e)} disabled={isHwSelectorDisabled}>
                            <option value={0}>{isLookingUpHardware ? 'Searching for available hardware...' : 'Please select hardware model'}</option>
                            {this.renderOptions('availableHardware')}
                        </Form.Control>
                    </Form.Group>
                    <Form.Group>
                        <Form.Label className={styles.blockLabel}>3. Select software SKU</Form.Label>
                        <Form.Control as="select" value={selectedSw} onChange={e => this.handleSwSelect(e)} disabled={isSwSelectorDisabled}>
                            <option value={0}>Please select software SKU</option>
                            {this.renderOptions('availableSoftware')}
                        </Form.Control>
                    </Form.Group>
                    <div className={styles.buttonsContainer}>
                        <Button variant="secondary" onClick={() => this.setState(this.initialState)}>
                            Reset Form
                        </Button>
                        <Button onClick={() => this.createLicenseKey()} disabled={isCreatingLicense || !selectedSw}>
                            {isCreatingLicense ? 'Creating license...' : 'Create license'}
                        </Button>
                    </div>
                </Form>
                <LicenseKeyModal keyValue={licenseKey} onDismiss={() => this.setState(this.initialState)}/>
            </>
        );
    }
}

const mapStateToProps = (state) => ({
    swPids: state.licenses.swpids,
    hwPids: state.licenses.hwpids,
    retrieved: state.licenses.retrieved
});

const mapDispatchToProps = {
    processFetchLicenseFormsRequest
};

LicenseCreate.propTypes = {
    processFetchLicenseFormsRequest: PropTypes.func,
    swPids: PropTypes.array,
    hwPids: PropTypes.array,
    retrieved: PropTypes.bool
};

export default connect(mapStateToProps, mapDispatchToProps)(LicenseCreate);
