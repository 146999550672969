import PropTypes from 'prop-types';
import React from 'react';
import { Button } from 'react-bootstrap';
import { connect } from 'react-redux';

import { fetchAgreementsFromServer, sendAgreementsToServer } from '../../state/auth/dispatchers';

class UserAgreements extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            termsOfServiceAgreement: true,
            marketingCorrespondenceAgreement: false,
            privacyPolicyAgreement: true
        };
    }

    componentDidMount() {
        this.props.fetchAgreementsFromServer();
    }

    componentDidUpdate(prevProps) {
        if (this.props.agreements !== prevProps.agreements) {
            this.setState(this.props.agreements);
        }
    }

    handleSaveAgreements() {
        this.props.sendAgreementsToServer({ agreement: this.state });
    }

    handleCheckbox(e, name) {
        this.setState({ [name]: !this.state[name] });
    }

    render() {
        return (
            <>
                <div className="pn-section-header pn-color">YOUR CHOICES</div>
                <p>
                    You have the right to opt-out of any marketing communications you receive from us.
                </p>
                <p>
                    <label style={{ fontWeight: 'normal' }}>
                        <input type="checkbox" style={{ marginRight: 10 }} checked={this.state.marketingCorrespondenceAgreement}
                               onChange={
                                   (e) => this.handleCheckbox(e, 'marketingCorrespondenceAgreement')
                               }/>
                        I want to receive occasional marketing emails related to my Pluribus Networks Cloud account (optional)
                    </label>
                </p>
                <p>
                    Note that this is separate from your Pluribus Customer Portal account, which you can access via Salesforce.
                </p>
                <p>
                    <Button className="btn pn-bg-color pn-white" onClick={() => this.handleSaveAgreements() }>Save</Button>
                </p>
                <hr/>
            </>
        );
    }
}

const mapStateToProps = (state) => ({
    agreements: state.auth.agreements
});

const mapDispatchToProps = {
    sendAgreementsToServer,
    fetchAgreementsFromServer
};

UserAgreements.propTypes = {
    agreements: PropTypes.object,
    fetchAgreementsFromServer: PropTypes.func,
    sendAgreementsToServer: PropTypes.func
};

export default connect(mapStateToProps, mapDispatchToProps)(UserAgreements);
