import PropTypes from 'prop-types';
import React from 'react';
import { Button, Modal } from 'react-bootstrap';
import { connect } from 'react-redux';

import { processDismissModal, processShowModal } from '../../../state/pncmodal/dispatchers';

class PNCModal extends React.Component {
    dismissModal(button) {
        this.props.processDismissModal();
        if (button && button.callback) button.callback();
    }

    render() {
        const { type } = this.props;
        return (
            <Modal show={this.props.display} size='lg' onHide={(e) => { this.dismissModal(); }}>
                <Modal.Header className={`white ${type === 'error' ? 'pn-red-bg' : 'pn-green-bg'}`} closeButton>
                    <Modal.Title className='h6'>{this.props.title}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className='mb10' dangerouslySetInnerHTML={{ __html: this.props.message }}/>
                </Modal.Body>
                <Modal.Footer>
                    {
                        this.props.buttons.map((button, index) =>
                            <Button key={index}
                                    variant={ button.variant }
                                    onClick={(e) => { this.dismissModal(button); }}>
                                {button.label}
                            </Button>
                    )}
                </Modal.Footer>
            </Modal>
        );
    }
}

const mapStateToProps = (state) => ({
    display: state.pncmodal.display,
    type: state.pncmodal.type,
    title: state.pncmodal.title,
    message: state.pncmodal.message,
    buttons: state.pncmodal.buttons
});

const mapDispatchToProps = {
    processShowModal,
    processDismissModal
};

PNCModal.propTypes = {
    display: PropTypes.bool,
    type: PropTypes.string,
    title: PropTypes.string,
    message: PropTypes.string,
    buttons: PropTypes.arrayOf(
        PropTypes.shape({
            variant: PropTypes.string, // ButtonProps.variant
            label: PropTypes.string,
            callback: PropTypes.func
        })),
    processShowModal: PropTypes.func,
    processDismissModal: PropTypes.func
};

export default connect(mapStateToProps, mapDispatchToProps)(PNCModal);
