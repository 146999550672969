import PropTypes from 'prop-types';
import React from 'react';
import { Button } from 'react-bootstrap';
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';

import { processLoginRequest, resetLoginForm } from '../../state/auth/dispatchers';

import styles from './Login.module.css';

class Login extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            login_email: '',
            login_password: ''
        };
    }

    handleInput(e, name) {
        const { error, resetLoginForm } = this.props;
        this.setState(
            { [name]: e.target.value },
            () => { if (error) resetLoginForm(); }
        );
    }

    handleInputEnter(e) {
        if (e.key === 'Enter') this.handleLogin(e);
    }

    handleLogin(e) {
        e.preventDefault();
        this.props.processLoginRequest(this.state);
    }

    handleSupportLogin(e) {
        e.preventDefault();
        window.location.replace('/oauth/login/salesforce-oauth2/?next=/dashboard');
    }

    handlePasswordReset(e) {
        e.preventDefault();
        window.location.replace('https://pluribus.my.salesforce.com/secur/forgotpasswordp.jsp?orgId=00Di0000000J2ne&portalId=060i00000009Mdu&retURL=%2Fsecur%2Flogin_portal.jsp');
    }

    render() {
        if (this.props.user) return <Redirect to="/dashboard" />;

        const { error } = this.props;
        return (
            <>
                <div className="sm-container">
                    <div className="title centered">
                        <span>Log in to Pluribus Networks Cloud</span>
                        <hr/>
                        <button onClick={(e) => this.handleSupportLogin(e)} name="btn_support_login" className="btn btn-primary centered">
                            Log in with your Support credentials
                        </button>
                    </div>
                    <div className={styles.orContainer}>
                        <hr className={styles.orHr}/>
                        <div id={styles.or}>or</div>
                    </div>
                    <div className="form-group">
                        <input placeholder="Your email" onChange={(e) => this.handleInput(e, 'login_email')} type="text" className="form-control" autoComplete="off" />
                    </div>
                    <div className="form-group">
                        <input placeholder="Password" onChange={(e) => this.handleInput(e, 'login_password')} onKeyDown={e => this.handleInputEnter(e)} type="password" className="form-control" autoComplete="off" />
                    </div>
                    <div className="pn-red">{error}</div>
                    <Button onClick={(e) => this.handleLogin(e)} className="btn pn-bg-color pn-white btn-login">Log in</Button>
                    <div id={styles.resetPassword} >
                        <Button onClick={(e) => this.handlePasswordReset(e)} variant="link" className="pn-green">Forgot password?</Button>
                    </div>
                    <div id={styles.newAccount}>
                        <span>Don{'\''}t have an account? </span>
                        <a href="http://www.pluribusnetworks.com/support/product-registration/" target="_parent" className="pn-link-color">Sign up!</a>
                    </div>
                </div>
            </>
        );
    }
}

const mapStateToProps = (state) => ({
    user: state.auth.user,
    loading: state.auth.loading,
    error: state.auth.error
});

const mapDispatchToProps = {
    processLoginRequest, resetLoginForm
};

Login.propTypes = {
    user: PropTypes.object,
    loading: PropTypes.bool,
    error: PropTypes.string,
    processLoginRequest: PropTypes.func,
    resetLoginForm: PropTypes.func
};

export default connect(mapStateToProps, mapDispatchToProps)(Login);
