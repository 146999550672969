import axios from 'axios';
import PropTypes from 'prop-types';
import React from 'react';
import { Accordion, Button, Card } from 'react-bootstrap';
import { CSVLink } from 'react-csv';
import { connect } from 'react-redux';
import { toast } from 'react-toastify';

import { processActivateDeviceRequest, processDeactivateDeviceRequest, processFetchOrderDetailsRequest } from '../../state/orderActivations/displatchers';
import { processEulaAcceptanceRequest, processFetchModalContentRequest } from '../../state/softwareDownloads/dispatchers';
import { filenameFromContentDisposition, triggerDownload } from '../../utils/downloader';
import log from '../../utils/logger';
import pncmodal from '../../utils/pncmodal';
import Spinner from '../Common/Spinner';
import LicenseKeyModal from '../modals/LicenseKeyModal';

import DeviceActivations from './DeviceActivations';
import styles from './OrderActivations.module.css';

const productTypesWithActivationKeysDisabled = ['VCFC'];

class OrderActivations extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            keyToDisplay: null,
            eulaDisplayed: false,
            eulaAcceptTrigger: null,
            currentOrder: null,
            currentDeviceId: null,
            activeOrderIndex: null,
            ordersGrouped: false
        };
        this.activateDevice = this.activateDevice.bind(this);
        this.deactivateDevice = this.deactivateDevice.bind(this);
        this.displayLicenseKey = this.displayLicenseKey.bind(this);
    }

    componentDidMount() {
        this.setActiveOrderIndex();
        if (!this.props.retrieved) this.props.processFetchOrderDetailsRequest();
    }

    componentDidUpdate(prevProps) {
        if (prevProps.orderDetails !== this.props.orderDetails) this.setActiveOrderIndex();
    }

    setActiveOrderIndex() {
        if (this.props.location.hash) {
            const idx = this.props.orderDetails.findIndex(order => order.auth_code === this.props.location.hash.substring(1)) + 1;
            this.setState(
                { activeOrderIndex: idx },
                () => {
                    const card = document.getElementById(`card${idx}`);
                    card && card.scrollIntoView(true);
                }
            );
        } else {
            this.setState({ activeOrderIndex: null });
        }
    }

    checkEula() {
        const { currentOrder, currentDeviceId, eulaAcceptTrigger } = this.state;
        if (currentOrder.eula_accepted) {
            eulaAcceptTrigger === 'downloadKeyBundle' && this.downloadKeyBundle(currentOrder);
            eulaAcceptTrigger === 'activateDevice' && this.requestDeviceActivation(currentOrder, currentDeviceId);
        } else {
            this.setState({ eulaDisplayed: true });
            this.props.processFetchModalContentRequest(currentOrder.sw_pid);
        }
    }

    acceptEula() {
        const { currentOrder, currentDeviceId, eulaAcceptTrigger } = this.state;
        this.props.processEulaAcceptanceRequest(
            { version: currentOrder.version, sw_pid: currentOrder.sw_pid, id: currentOrder.id }
        );
        eulaAcceptTrigger === 'downloadKeyBundle' && this.downloadKeyBundle(currentOrder);
        eulaAcceptTrigger === 'activateDevice' && this.requestDeviceActivation(currentOrder, currentDeviceId);
        this.setState({ eulaDisplayed: false, currentOrder: null, currentDeviceId: null, eulaAcceptTrigger: null });
    }

    /**
    * Check that the device can be activated
    * Calls Redux action processActivateDeviceRequest
    */

    activateDevice(orderDetail, deviceId) {
        this.setState(
            { currentOrder: orderDetail, currentDeviceId: deviceId, eulaAcceptTrigger: 'activateDevice' },
            () => this.checkEula()
        );
    }

    requestDeviceActivation(orderDetail, deviceId) {
        const validatingToast = toast.info('Activating device...', { autoClose: 2000 });
        const deviceIdCheckUrl = '/api/orderActivations/checkDeviceID';
        const errorMessage = 'Device ID appears to be invalid.';
        const activationData = { order_detail_id: orderDetail.id, device_id: deviceId };

        axios.post(deviceIdCheckUrl, activationData)
            .then(response => {
                if (response.data.success === true) {
                    this.props.processActivateDeviceRequest(activationData);
                } else {
                    toast.dismiss(validatingToast);
                    pncmodal.error(errorMessage, 'Device activation failed');
                    log.error(errorMessage, activationData);
                }
            })
            .catch(error => {
                toast.dismiss(validatingToast);
                pncmodal.error(errorMessage, 'Device activation failed');
                log.error(errorMessage, error);
            });
    }

    /**
     * Checks that device can be deactivated
     * - check with Update Server that the device is not active
     * - ask user for confirmation
     * After this calls Redux action processDeactivateDeviceRequest
     */
    deactivateDevice(orderDetail, orderActivation) {
        // Verify that the device can be deactivated (it never connected to PNCloud)
        axios.get('/api/switches', {
            params: { deviceId: orderActivation.device_id }
        }).catch(error => {
            pncmodal.error('Device deactivation failed');
            log.error('Failed to fetch switch information', error);
        }).then(response => {
            // Check if the response.switches[0].lastConnect is empty
            if (response && response.switches && response.switches[0].lastConnect) {
                return pncmodal.error('This device is active and can\'t be deactivated',
                    'Cannot deactivate device');
            }

            // Ask user for confirmation
            pncmodal.info('Are you sure you want to deactivate this device?',
                'Deactivate device ' + orderActivation.device_id, [
                    { label: 'Cancel', variant: 'secondary' },
                    {
                        label: 'Deactivate',
                        variant: 'danger',
                        callback: () => {
                            toast.info('Deactivating device...', { autoClose: 2000 });
                            this.props.processDeactivateDeviceRequest({
                                order_detail_id: orderDetail.id,
                                device_id: orderActivation.device_id
                            });
                        }
                    }
                ]);
        });
    }

    /**
     * Starts a streaming download of binary activation keys package from Upload Server
     * - check that the user accepted the EULA
     * - ask user for confirmation od download
     * Key bundle is streamed to the browser using Axios (with 'arraybuffer' option
     * to handle binary data). The response content is then packaged into a JS Blob,
     * which is used to create an URL-encoded file, on which a download is triggered.
     */

    handleDownloadKeyBundle(orderDetail) {
        this.setState({ currentOrder: orderDetail, eulaAcceptTrigger: 'downloadKeyBundle' });
        pncmodal.info('<div class="pn-font-s3 pn-gray pn-font">\n' +
            '    You can generate, download and install activation keys for each of your\n' +
            '    devices. Each device will know the exact key to use so you won’t have\n' +
            '    to keep track of which key goes with which device.\n' +
            '<br/><br/>Click Continue to start generating keys now.</div>',
        'Generate Activation Keys', [
            { label: 'Cancel', variant: 'secondary' },
            {
                label: 'Continue',
                variant: 'primary',
                callback: () => this.checkEula()
            }
        ]);
    }

    downloadKeyBundle(orderDetail) {
        const genToast = toast.info('Generating keys, please wait a few seconds...');
        axios.get('/api/offline_bundle/' + orderDetail.id, { responseType: 'arraybuffer' })
            .then(response => {
                const filename = filenameFromContentDisposition(response, 'activation-keys');
                const blob = new Blob([response.data], { type: 'application/octet-stream' });
                const blobUrl = URL.createObjectURL(blob);
                triggerDownload(blobUrl, filename);
                toast.dismiss(genToast);
            })
            .catch(error => {
                toast.dismiss(genToast);
                toast.error('There was an error creating the package. Please try again later.');
                log.error('Failed to download activation keys.', error);
            });
    }

    /**
     * Displays a modal dialog with license key information
     */
    displayLicenseKey(key) {
        if (key) {
            this.setState({ keyToDisplay: key });
        } else {
            toast.error('License key could not be found.');
        }
    }

    prepareAllData() {
        const data = [];
        const orderDetails = this.sortOrderDetails(this.props.orderDetails);

        orderDetails.forEach(order => {
            if (order.order_activations.length > 0) {
                order.order_activations.forEach(activation => {
                    data.push(
                        {
                            sw_pid: order.sw_pid,
                            auth_code: order.auth_code,
                            device_id: activation.device_id,
                            activation_date: activation.activation_date,
                            expires_in_days: activation.expires_in_days,
                            license_key: activation.license_key,
                            id: activation.id,
                            order_detail_id: activation.order_detail_id,
                            order_number: order.order_number
                        }
                    );
                });
            }
        });
        return data;
    }

    sortOrderDetails(orderDetails) {
        const sortCriterium = this.state.ordersGrouped ? 'order_number' : 'sw_pid';
        return orderDetails.sort(
            (a, b) => a[sortCriterium].localeCompare(b[sortCriterium])
        );
    }

    render() {
        const activeTab = this.state.activeOrderIndex;
        const eulaTitle = 'End-User License Agreement (EULA)';
        const orderDetails = this.sortOrderDetails(this.props.orderDetails);

        if (this.props.loading) {
            return <Spinner />;
        }
        return (
            <>
                <div>
                    <div className='pn-section-header pn-green' >Activation Keys</div>
                    <div style={{ marginBottom: 20 }}>
                        <p>
                            Listed below are all of your activated devices and the licenses available for that device.
                            Select and enter license and click on the Activate button.
                            You can also click on the `Activation keys` button to download the entire pack for each activation.
                        </p>
                        <input
                            type="checkbox"
                            checked={ this.state.ordersGrouped }
                            onClick={ () => this.setState({ ordersGrouped: !this.state.ordersGrouped })}
                        />
                        <span className="pn-green" style={{ marginLeft: 5, marginRight: 20 }}>Group by order</span>
                        { this.props.orderDetails.length > 0 &&
                            <CSVLink data={this.prepareAllData()} filename={'all_licenses.csv'}>
                                <i className="fa fa-icon fa-download pn-green"/>
                                Export all licenses (CSV)
                            </CSVLink>
                        }
                    </div>
                    <div className='row'>
                        <div className='col-md-12'>
                            <Accordion defaultActiveKey={activeTab} ref={this.accordionRef}>
                                { orderDetails.map((orderDetail, index) => {
                                const hasOrderNumberChanged = index <= orderDetails.length - 2 && orderDetails[index].order_id !== orderDetails[index + 1].order_id;
                                const idx = index + 1;
                                const isActive = idx === this.state.activeOrderIndex;
                                const orderItemClass = isActive ? styles.pnActiveLicense + ' pn-bg-gray' : styles.pnLicense;
                                return (
                                    <Card key={orderDetail.id} style={ this.state.ordersGrouped && hasOrderNumberChanged ? { marginBottom: 40 } : {} }>
                                        <Accordion.Toggle as={Card.Header} eventKey={idx} id={`card${idx}`}>
                                            <div className={`row pointer ${orderItemClass}`} onClick={() => this.setState({ activeOrderIndex: idx })}>
                                                <div className="col-md-8">
                                                    <div className="font-sm">
                                                        <strong>{orderDetail.product_name}</strong>
                                                    </div>
                                                    <div className="font-sm">
                                                        {orderDetail.sw_pid}
                                                    </div>
                                                    <div className="font-sm">
                                                        <strong>Auth code:</strong> {orderDetail.auth_code}
                                                    </div>
                                                    <div className="font-sm">
                                                        <strong>Order number:</strong> {orderDetail.order_number}
                                                    </div>
                                                </div>
                                                <div className="col-md-4 font-sm txt-right" style={{ marginTop: 10 }}>
                                                    <span style={{ marginRight: 10 }}>In use {orderDetail.order_activations.length} of {orderDetail.quantity}</span>
                                                    <Button className="btn pn-green-bg white" disabled={!orderDetail.order_activations.length || productTypesWithActivationKeysDisabled.includes(orderDetail.product_type) } onClick={(e) => { e.stopPropagation(); this.handleDownloadKeyBundle(orderDetail); }}>
                                                        <i className="fa fa-download"/> Activation Keys
                                                    </Button>
                                                </div>

                                            </div>
                                        </Accordion.Toggle>
                                        <Accordion.Collapse eventKey={idx}>
                                            <DeviceActivations orderDetail={orderDetail}
                                                                activateDevice={this.activateDevice}
                                                                deactivateDevice={this.deactivateDevice}
                                                                displayLicenseKey={this.displayLicenseKey}/>
                                        </Accordion.Collapse>
                                    </Card>
                                );
                            })}
                            </Accordion>
                        </div>
                    </div>
                    {/* <pre>{JSON.stringify(this.props.orderDetails, null, 2) }</pre> */}
                </div>
                <LicenseKeyModal keyValue={this.state.keyToDisplay} onDismiss={() => this.setState({ keyToDisplay: null })} />
                {this.state.eulaDisplayed && pncmodal.info(this.props.eulaContent, eulaTitle, [
                    {
                        label: 'Decline',
                        variant: 'secondary',
                        callback: () => this.setState({ eulaDisplayed: false })
                    },
                    {
                        label: 'Accept',
                        variant: 'primary',
                        callback: () => this.acceptEula()
                    }
                ])}
            </>
        );
    }
}

const mapStateToProps = (state) => ({
    orderDetails: state.orderActivations.orderDetails,
    loading: state.orderActivations.loading,
    eulaContent: state.softwareDownloads.eula,
    retrieved: state.orderActivations.retrieved
});

const mapDispatchToProps = {
    processFetchOrderDetailsRequest,
    processActivateDeviceRequest,
    processDeactivateDeviceRequest,
    processEulaAcceptanceRequest,
    processFetchModalContentRequest
};

OrderActivations.propTypes = {
    processFetchOrderDetailsRequest: PropTypes.func,
    processActivateDeviceRequest: PropTypes.func,
    processDeactivateDeviceRequest: PropTypes.func,
    processFetchModalContentRequest: PropTypes.func,
    processEulaAcceptanceRequest: PropTypes.func,
    retrieved: PropTypes.bool,
    eulaContent: PropTypes.string,
    orderDetails: PropTypes.array,
    loading: PropTypes.bool,
    location: PropTypes.object
};

export default connect(mapStateToProps, mapDispatchToProps)(OrderActivations);
