import * as actionTypes from '../actions/actionTypes';

const initialState = {
    loading: false,
    retrieved: false,
    assets: []
};

export default (state = initialState, action) => {
    switch (action.type) {
    case actionTypes.FETCH_ASSETS_INIT:
        return { ...state, loading: true };
    case actionTypes.FETCH_ASSETS_DONE:
        return { ...state, assets: action.payload, loading: false, retrieved: true, deferred: false };
    case actionTypes.FETCH_ASSETS_FAIL:
        return { ...state, assets: [], loading: false, retrieved: false, deferred: false };
    case actionTypes.FETCH_ASSETS_DEFERRED:
        return { ...state, assets: [], loading: false, retrieved: false, deferred: true };
    default:
        return state;
    }
};
