import React from 'react';
import { Navbar } from 'react-bootstrap';

import HeaderMenu from '../HeaderMenu';

import styles from './NavBar.module.css';

const NavBar = (props) => (
    <Navbar fixed="top" variant="dark" className={`${styles.pnNavbar} pn-green-bg`}>
        <Navbar.Brand className={`${styles.pnBrand} title`}>
            Pluribus Networks Cloud
        </Navbar.Brand>
        <Navbar.Collapse className="justify-content-end">
            <HeaderMenu />
        </Navbar.Collapse>
    </Navbar>
);

export default NavBar;
