import axios from 'axios';

import { fetchLicenseFormsFailed, fetchLicenseFormsInitialized, fetchLicenseFormsSucceeded } from '../actions/actionCreators';

export const processFetchLicenseFormsRequest = () => {
    return dispatch => {
        dispatch(fetchLicenseFormsInitialized());

        const donwloadSwpids = axios.get('/api/swpids');
        const downloadHwpids = axios.get('/api/hwpids');

        axios.all([donwloadSwpids, downloadHwpids])
            .then(axios.spread((...responses) => {
                const swpids = responses[0].data.swpid;
                const hwpids = responses[1].data.hwpid;
                if (swpids && hwpids) {
                    dispatch(fetchLicenseFormsSucceeded({ swpids, hwpids }));
                } else {
                    const errors = [responses[0].data, responses[1].data];
                    dispatch(fetchLicenseFormsFailed(errors.join(',')));
                }
            }))
            .catch(
                error => {
                    dispatch(fetchLicenseFormsFailed(!error.response ? 'NetworkError' : `Error ${error.response.status}. Please contact support for help.`));
                }
            );
    };
};
