const triggerDownload = (fileUrl, fileName) => {
    // Create a link element to initiate download
    const link = document.createElement('a');
    link.href = fileUrl;
    if (fileName) link.download = fileName;
    document.body.appendChild(link);
    // Force download
    link.click();
    // Clean up and remove the link
    link.parentNode.removeChild(link);
};

/*
 * Return filename from a content-disposition header of an Axios response object
 */
const filenameFromContentDisposition = (response, defaultName) => {
    const disposition = response.headers['content-disposition'];
    if (disposition && disposition.startsWith('attachment')) {
        return disposition.split('filename=')[1].replace(/['"]+/g, '');
    } else {
        return defaultName || 'pnc-download';
    }
};

export { filenameFromContentDisposition, triggerDownload };
