import axios from 'axios';
import React from 'react';
import { Button, Form, FormControl, Navbar, NavDropdown, Table } from 'react-bootstrap';
import { toast } from 'react-toastify';

import log from '../../utils/logger';
import pncmodal from '../../utils/pncmodal';
import Spinner from '../Common/Spinner';

import styles from './LicenseHistory.module.css';

export class LicenseHistory extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            isLoading: false,
            licenses: [],
            sortProperty: 'created',
            sortOrder: 'desc',
            searchPhrase: '',
            limit: 200,
            offset: 0,
            totalResults: 0,
            hasMoreResults: null,
            hasChangedCriteria: null
        };
    }

    componentDidMount() {
        const settings = {
            sortOrder: localStorage.getItem('pncloud.userSetting.sortOrder'),
            sortProperty: localStorage.getItem('pncloud.userSetting.sortProperty'),
            limit: localStorage.getItem('pncloud.userSetting.limit')
        };
        Object.keys(settings).forEach(key =>
            settings[key] && this.setState({ [key]: settings[key] })
        );
        this.loadData();
    }

    componentWillUnmount() {
        document.removeEventListener('scroll', this.trackScrolling);
    }

    getParamVerboseName = (param) => {
        switch (param) {
        case 'created':
            return 'Created at';
        case 'asc':
            return 'Ascending';
        case 'desc':
            return 'Descending';
        default:
            return param[0].toUpperCase() + param.slice(1);
        }
    };

    trackScrolling = () => {
        // Load more items when scrolling down the table and bottom was reached
        const wrappedElement = document.getElementById('licenses');
        if (wrappedElement.getBoundingClientRect().bottom <= window.innerHeight) {
            if (this.state.hasMoreResults) {
                this.setState(
                    { isLoading: true },
                    this.loadData
                );
            } else {
                document.removeEventListener('scroll', this.trackScrolling);
                toast.info('No more licenses to load', { autoClose: 2000 });
            }
        }
    };


    loadData() {
        const { limit, offset, sortProperty, sortOrder, searchPhrase } = this.state;
        const params = { limit, offset, sort_field: sortProperty, sort_order: sortOrder };
        if (searchPhrase) params.filter = searchPhrase;

        axios.get('/api/licenses', { params })
            .then(response => {
                const hasMoreResults = response.data.totalResults - offset > limit;

                this.setState(prevState => ({
                    licenses: [...prevState.licenses, ...response.data.licenses],
                    totalResults: response.data.totalResults,
                    isLoading: false,
                    offset: prevState.offset + this.state.limit,
                    hasMoreResults,
                    hasChangedCriteria: false
                }));
                hasMoreResults && document.addEventListener('scroll', this.trackScrolling);
            })
            .catch(error => {
                pncmodal.error(error, 'Failed to load license information');
                log.error('Failed to load license information', error);
            });
    }

    handleInput(e, name) {
        this.setState({ [name]: e.target.value, hasChangedCriteria: true });
    }

    handleInputEnter(e) {
        if (e.key === 'Enter') this.handleSearch(e);
    }

    handleSelect(name, value) {
        localStorage.setItem(`pncloud.userSetting.${name}`, value);
        this.setState({ [name]: value, hasChangedCriteria: true });
    }

    handleSearch = (e) => {
        e.preventDefault();
        this.setState({ licenses: [], offset: 0, isLoading: true }, () => this.loadData());
    };

    renderTableHeader() {
        return (
            <tr className="table-header">
                <th>Serial</th>
                <th>Code</th>
                <th>Key</th>
                <th>Created at</th>
                <th>User</th>
                <th>Comment</th>
            </tr>
        );
    }

    renderTableContent() {
        return this.state.licenses.map((license, index) => (
            <tr key={index}>
                <td>{license.serial}</td>
                <td>{license.code}</td>
                <td>{license.key}</td>
                <td>{new Date(license.created).toLocaleString()}</td>
                <td>{license.user}</td>
                <td>{license.comment}</td>
            </tr>
        ));
    }

    renderSearchBar() {
        const { sortProperty, sortOrder, totalResults } = this.state;
        const paramOptions = ['serial', 'code', 'key', 'created', 'user', 'comment'];
        const sortOrderOptions = ['asc', 'desc'];
        const itemsPerPageOption = [50, 100, 200];
        const params = new URLSearchParams({ limit: totalResults, offset: 0, sort_field: sortProperty, sort_order: sortOrder, download: true });
        const downloadUrl = '/api/licenses?' + params.toString();
        const downloadLinkClass = this.state.hasChangedCriteria ? styles.linkInactive : 'pn-green';

        return (
            <>
                <Navbar bg="light" variant="light" className="sticky">
                    <Form inline>

                        <FormControl type="text" placeholder="Search" value={this.state.searchPhrase} onKeyDown={e => this.handleInputEnter(e)} onChange={(e) => this.handleInput(e, 'searchPhrase')} className="mr-sm-2" />
                        <Button variant="outline-primary" onClick={this.handleSearch} >Search</Button>

                        <div>
                            <NavDropdown title="Sort by">
                                {paramOptions.map(param =>
                                    <NavDropdown.Item key={param} onSelect={() => this.handleSelect('sortProperty', param)}>
                                        {this.getParamVerboseName(param)}
                                    </NavDropdown.Item>
                            )}
                            </NavDropdown>
                            <p className={styles.navbarDesc}>{this.getParamVerboseName(this.state.sortProperty)}</p>
                        </div>

                        <div>
                            <NavDropdown title="Sort order">
                                {sortOrderOptions.map(param =>
                                    <NavDropdown.Item key={param} onSelect={() => this.handleSelect('sortOrder', param)}>
                                        {this.getParamVerboseName(param)}
                                    </NavDropdown.Item>
                            )}
                            </NavDropdown>
                            <p className={styles.navbarDesc}>{this.getParamVerboseName(this.state.sortOrder)}</p>
                        </div>

                        <div>
                            <NavDropdown title="Results" id="basic-nav-dropdown">
                                {itemsPerPageOption.map(param =>
                                    <NavDropdown.Item key={param} onSelect={() => this.handleSelect('limit', param)}>
                                        {param}
                                    </NavDropdown.Item>
                            )}
                            </NavDropdown>
                            <p className={styles.navbarDesc}>{this.state.limit}</p>
                        </div>
                    </Form>
                </Navbar>
                {Boolean(this.state.totalResults) &&
                    <div className={`${styles.resultsCount} bg-light`}>
                        <span>Found {this.state.totalResults} license(s). </span>
                        {this.state.hasMoreResults && <span>Scroll to the bottom of the list to load more results.</span>}
                        <div style={{ marginTop: 10 }}>
                            <i className={`${downloadLinkClass} fa fa-icon fa-download`} />
                            <a className={downloadLinkClass} href={downloadUrl}>Download results (csv)</a>
                        </div>
                    </div>
                }
            </>
        );
    }

    render() {
        return (
            <>
                <div className='pn-section-header left pn-green' >License history</div>
                { this.renderSearchBar()}
                <div id="licenses" className="content">
                    {Boolean(this.state.licenses.length) &&
                        <Table className="font-sm" hover bordered>
                            <thead>
                                { this.renderTableHeader() }
                            </thead>
                            <tbody>
                                {this.renderTableContent()}
                            </tbody>
                        </Table>
                    }
                    {this.state.isLoading &&
                        <div style={{ padding: 15 }}>
                            <Spinner />
                        </div>
                    }
                </div>
            </>
        );
    }
}

export default LicenseHistory;
