import * as actionTypes from './actionTypes';

export const fetchAssetsInitialized = () => {
    return {
        type: actionTypes.FETCH_ASSETS_INIT
    };
};

export const fetchAssetsSucceeded = (assets) => {
    return {
        type: actionTypes.FETCH_ASSETS_DONE,
        payload: assets
    };
};

export const fetchAssetsFailed = (error) => {
    return {
        type: actionTypes.FETCH_ASSETS_FAIL,
        payload: error
    };
};

export const fetchAssetsDeferred = () => {
    return {
        type: actionTypes.FETCH_ASSETS_DEFERRED
    };
};
