import * as actionTypes from '../actions/actionTypes';

const initialState = {
    loading: false,
    error: null,
    device: null
};

export default (state = initialState, action) => {
    switch (action.type) {
    case actionTypes.FETCH_DEVICE_DETAILS_INIT:
        return { ...state, loading: true };
    case actionTypes.FETCH_DEVICE_DETAILS_DONE:
        return { ...state, device: action.payload, loading: false, error: null };
    case actionTypes.FETCH_DEVICE_DETAILS_FAIL:
        return { ...state, device: null, loading: false, error: action.payload };
    case actionTypes.UPDATE_LICENSE: {
        const newState = { ...state };
        newState.device.licenses[action.payload.index].key = action.payload.licenseKey;
        return newState;
    }
    default:
        return state;
    }
};
