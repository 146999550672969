import _ from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';
import { Button, Form, InputGroup, Table } from 'react-bootstrap';
import { CSVLink } from 'react-csv';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import ReactRouterPropTypes from 'react-router-prop-types';

import { processFetchAssetsRequest } from '../../state/assets/dispatchers';
import permissionHelper from '../../utils/permission-helper';
import Spinner from '../Common/Spinner';

import styles from './Assets.module.css';

const timeInMs = { '2d': 172800000, '5d': 432000000, '14d': 1209600000, '9m': 23587200000, '1y': 31536000000, '2y': 63072000000 };
let deferredDataCheck;

export class Assets extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            assets: [],
            sortProperty: 'lastConnect',
            sortOrder: -1,
            filterProperty: 'lastConnect',
            filterValue: 'all',
            searchPhrase: null
        };
        this.hasAccess = permissionHelper(this.props.user);
    }

    setAssetsFromProps() {
        this.setState(
            { assets: this.props.assets },
            () => this.applySorting()
        );
    }

    componentDidMount() {
        if (!this.props.retrieved) {
            this.props.processFetchAssetsRequest();
        } else {
            this.setAssetsFromProps();
        }
    }

    componentDidUpdate(prevProps) {
        const { deferred, retrieved, assets } = this.props;
        if (assets !== prevProps.assets) this.setAssetsFromProps();
        if (deferred && deferred !== prevProps.deferred && !retrieved) {
            deferredDataCheck = setInterval(() => this.props.processFetchAssetsRequest(), 5000);
        }
        if (retrieved !== prevProps.retrieved) {
            clearInterval(deferredDataCheck);
        }
    }

    handleAppliedFilter(event, name) {
        // generic state setter for handling
        // 'searchPhrase', 'filterProperty' and 'filterValue' inputs
        // setting state with given keys and user input values
        // once state is set the filtering function is launched
        this.setState(
            { [name]: event.target.value },
            () => this.applyFiltering()
        );
    }

    prepareExport() {
        const data = _.cloneDeep(this.state.assets);
        data.forEach(device => {
            const activations = device.activationDetails;

            if (Array.isArray(activations)) {
                device.licenseInformation = activations.map(license =>
                    `| authCode: ${license.authCode}, activated: ${license.activatedOn}, expires: ${license.expiresOn} |`
                );
            }
            delete device.licenses;
            delete device.activationDetails;
        });
        return data;
    }

    applyFiltering() {
        const { filterProperty, filterValue, searchPhrase } = this.state;
        let matchingItems = this.props.assets;

        if (searchPhrase && searchPhrase.length >= 2) {
            matchingItems = this.props.assets.filter(asset =>
                Object.values(asset).some(val => val ? val.toString().toLowerCase().includes(searchPhrase.toLowerCase()) : false)
            );
        }

        if (filterValue === 'all') {
            this.setState({ assets: matchingItems });
        } else {
            const filteredItems = matchingItems.filter(asset =>
                new Date() - new Date(asset[filterProperty]) <= timeInMs[filterValue]);
            this.setState({ assets: filteredItems });
        }
    }

    handleSort(property) {
        const order = property === this.state.sortProperty ? this.state.sortOrder * -1 : 1;
        this.setState(
            { sortProperty: property, sortOrder: order },
            () => this.applySorting()
        );
    }

    applySorting() {
        const { sortProperty, sortOrder } = this.state;
        const sortFunction = (a, b) => {
            const result = (
                a[sortProperty] < b[sortProperty] ||
                b[sortProperty] === '' ||
                b[sortProperty] === null
            ) ? -1
                : (a[sortProperty] > b[sortProperty]) ? 1
                    : 0;
            return result * sortOrder;
        };
        this.setState({ assets: this.state.assets.sort(sortFunction) });
    }

    renderTableHeader() {
        const thClassName = (property) =>
            property === this.state.sortProperty ? `sorting ${this.state.sortOrder === 1 ? 'asc' : 'desc'}` : '';

        return (
            <tr className="table-header">
                <th className={thClassName('serialNumber')} onClick={() => this.handleSort('serialNumber')}>Serial Number</th>
                {this.hasAccess('salesforceAssets') &&
                    <>
                        <th className={thClassName('accountName')} onClick={() => this.handleSort('accountName')}>Account Name</th>
                        <th className={thClassName('contactName')} onClick={() => this.handleSort('contactName')}>Account Contact</th>
                    </>
                }
                <th className={thClassName('partNumber')} onClick={() => this.handleSort('partNumber')} >Hardware Model</th>
                <th className={thClassName('hostname')} onClick={() => this.handleSort('hostname')}>Hostname</th>
                <th className={thClassName('deviceId')} onClick={() => this.handleSort('deviceId')}>Device ID</th>
                <th className={thClassName('ip')} onClick={() => this.handleSort('ip')} >IP Address</th>
                <th className={thClassName('softwareVersion')} onClick={() => this.handleSort('softwareVersion')}>Software Version</th>
                <th className={thClassName('lastConnect')} onClick={() => this.handleSort('lastConnect')}>Last Connect</th>
                <th className={thClassName('lastCoreTime')} onClick={() => this.handleSort('lastCoreTime')}>Last Fault</th>
                <th>Details</th>
            </tr>
        );
    }

    renderTableContent() {
        const { shortList } = this.props;

        return this.state.assets.map((asset, index) => {
            if ((shortList && index < 5) || !shortList) {
                return (
                    <React.Fragment key={index}>
                        <tr>
                            <td>{asset.serialNumber}</td>
                            {this.hasAccess('salesforceAssets') &&
                                <>
                                    <td>
                                        <a href={asset.accountUrl} target="_blank" rel="noopener noreferrer" className="pn-green tdn">
                                            {asset.accountName}
                                        </a>
                                    </td>
                                    <td>
                                        <a href={asset.contactUrl} target="_blank" rel="noopener noreferrer" className="pn-green tdn">
                                            {asset.contactName}
                                        </a>
                                        {asset.contactEmail && <><br/><a href={`mailto:${asset.contactEmail}`}><i className="fa fa-icon fa-envelope" /></a></>}
                                    </td>
                                </>
                            }
                            <td>{asset.partNumber}</td>
                            <td>{asset.hostname}</td>
                            <td>{asset.deviceId}</td>
                            <td>{asset.ip}{asset.natip ? `${'\n'}(${asset.natip} behind NAT)` : ''}</td>
                            <td>{asset.softwareVersion}</td>
                            <td>{asset.lastConnect && new Date(asset.lastConnect).toLocaleString()}</td>
                            <td>{asset.lastCoreTime && new Date(asset.lastCoreTime).toLocaleString()}</td>
                            <td>
                                <Link to={`/device/${asset.serialNumber}`}>
                                    <Button size='sm'>
                                        <i className="fa fa-chevron-right"/>
                                    </Button>
                                </Link>
                            </td>
                        </tr>
                        { this.renderActivationDetails(asset) }
                    </React.Fragment>
                );
            }
            return null;
        });
    }

    renderActivationDetails(asset) {
        const activationDetails = asset.activationDetails;
        if (!activationDetails) return;

        const licenses = activationDetails.map((license, idx) => {
            const activatedOn = new Date(license.activatedOn).toLocaleString();
            const expiresOn = license.expiresOn ? new Date(license.expiresOn).toLocaleString() : 'never';
            return (
                <div key={`${license.authCode}${idx}`}>
                    <span className={styles.r20}>
                        <span className='pn-grey'>swPid: </span>{asset.swPid}
                    </span>
                    <span className={styles.r20}>
                        <span className='pn-grey'>authCode: </span>{license.authCode}
                    </span>
                    <span className={styles.r20}>
                        <span className='pn-grey'>activated: </span>{activatedOn}
                    </span>
                    <span className={styles.r20}>
                        <span className='pn-grey'>expires: </span>{expiresOn}
                    </span>
                </div>
            );
        });

        return (
            <tr>
                <td colSpan={100} style={{ border: 'none' }}>
                    <div>
                        <div><b>License information:</b></div>
                        {licenses}
                    </div>
                </td>
            </tr>
        );
    }

    render() {
        if (this.props.deferred) {
            return <Spinner message="Preparing data, please wait..." />;
        }
        if (this.props.loading) {
            return <Spinner />;
        }
        return (
            <div>
                <div className='pn-section-header pn-green' >{this.props.shortList ? 'RECENT DEVICES' : 'DEVICES'}</div>
                <div className='search-bar'>
                    <Form>
                        <InputGroup>
                            <Form.Label className={styles.inlineLabel}>Search</Form.Label>
                            <Form.Control className={styles.searchInput} size='sm' type="text" onChange={(e) => this.handleAppliedFilter(e, 'searchPhrase')}/>
                            <Form.Label className={styles.inlineLabel}>Show</Form.Label>
                            <Form.Control size='sm' as="select" onChange={(e) => this.handleAppliedFilter(e, 'filterProperty')}>
                                <option value="lastConnect">Last connection</option>
                                <option value="lastCoreTime">Last fault</option>
                            </Form.Control>
                            <Form.Label className={styles.inlineLabel}>within</Form.Label>
                            <Form.Control size='sm' as="select" onChange={(e) => this.handleAppliedFilter(e, 'filterValue')}>
                                <option value="all">All</option>
                                <option value="2d">2 days</option>
                                <option value="5d">5 days</option>
                                <option value="14d">14 days</option>
                                <option value="9m">9 months</option>
                                <option value="1y">1 year</option>
                                <option value="2y">2 years</option>
                            </Form.Control>
                        </InputGroup>
                    </Form>
                    {
                        this.state.assets.length > 0 &&
                            <div style={{ marginTop: 10 }}>
                                <CSVLink data={this.prepareExport()} filename="devices.csv">
                                    <i className="fa fa-icon fa-download pn-green" />
                                    Download results (csv)
                                </CSVLink>
                            </div>
                    }
                </div>
                <Table className="font-sm" responsive hover>
                    <thead>
                        { this.renderTableHeader() }
                    </thead>
                    <tbody>
                        { this.renderTableContent() }
                    </tbody>
                </Table>
            </div>
        );
    }
}

const mapStateToProps = (state) => ({
    user: state.auth.user,
    assets: state.assets.assets,
    retrieved: state.assets.retrieved,
    deferred: state.assets.deferred,
    loading: state.assets.loading
});

const mapDispatchToProps = {
    processFetchAssetsRequest
};

Assets.propTypes = {
    user: PropTypes.object,
    assets: PropTypes.array,
    loading: PropTypes.bool,
    retrieved: PropTypes.bool,
    deferred: PropTypes.bool,
    processFetchAssetsRequest: PropTypes.func,
    history: ReactRouterPropTypes.history,
    shortList: PropTypes.bool
};

export default connect(mapStateToProps, mapDispatchToProps)(Assets);
