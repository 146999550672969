import axios from 'axios';
import PropTypes from 'prop-types';
import React from 'react';
import StackTrace from 'stacktrace-js';

import NavBar from '../NavBar';

class ErrorBoundary extends React.Component {
    constructor(props) {
        super(props);
        this.state = { hasError: false };
    }

    static getDerivedStateFromError() {
        // Update state so the next render will show the fallback UI.
        return { hasError: true };
    }

    componentDidCatch(error) {
        StackTrace.fromError(error).then(err => {
            const errorMessage = {
                message: error.toString(),
                location: err[0].toString(),
                url: window.location.href,
                agent: window.navigator.userAgent
            };
            axios.post('/api/error_log', errorMessage);
        });
    }

    render() {
        if (this.state.hasError) {
            return (
                <>
                    <NavBar />
                    <div id="main">
                        <div style={{ marginTop: 80, marginLeft: 55 }}>
                            <span className='pn-red'>An unknown error has occured.</span>
                            <br />
                            <span>Click <a href={`//${window.location.host}`}>here</a> to restart application.</span>
                        </div>
                    </div>
                </>
            );
        }
        return this.props.children;
    }
}

export default ErrorBoundary;

ErrorBoundary.propTypes = {
    children: PropTypes.node.isRequired
};
