import * as actionTypes from './actionTypes';

export const loginInitialized = () => {
    return {
        type: actionTypes.LOGIN_INIT
    };
};

export const loginSucceeded = (user) => {
    return {
        type: actionTypes.LOGIN_DONE,
        payload: user
    };
};

export const loginFailed = (error) => {
    return {
        type: actionTypes.LOGIN_FAIL,
        payload: error
    };
};

export const logoutInitialized = () => {
    return {
        type: actionTypes.LOGOUT_INIT
    };
};

export const logoutSucceeded = () => {
    return {
        type: actionTypes.LOGOUT_DONE
    };
};

export const loginFormReset = () => {
    return {
        type: actionTypes.LOGIN_FORM_RESET
    };
};

export const logoutFailed = (error) => {
    return {
        type: actionTypes.LOGOUT_FAIL,
        payload: error
    };
};

export const eulaSucceeded = () => {
    return {
        type: actionTypes.EULA_DONE
    };
};

export const eulaFailed = (error) => {
    return {
        type: actionTypes.EULA_FAIL,
        payload: error
    };
};

export const agreementsFetchInitialized = () => {
    return {
        type: actionTypes.AGREEMENTS_FETCH_INIT,
        loading: true
    };
};

export const agreementsFetchSucceeded = (userAgreements) => {
    return {
        type: actionTypes.AGREEMENTS_FETCH_DONE,
        loading: false,
        payload: userAgreements
    };
};

export const agreementsFetchFailed = (error) => {
    return {
        type: actionTypes.AGREEMENTS_FETCH_FAIL,
        loading: false,
        payload: error
    };
};
