import { pncModalDismiss, pncModalShow } from '../actions/actionCreators';

export const processShowModal = (modalConfig) => {
    return dispatch => {
        dispatch(pncModalShow(modalConfig));
    };
};

export const processDismissModal = () => {
    return dispatch => {
        dispatch(pncModalDismiss());
    };
};
