import * as actionTypes from './actionTypes';

export const fetchDashboardInitialized = () => {
    return {
        type: actionTypes.FETCH_DASHBOARD_INIT
    };
};

export const fetchDashboardSucceeded = (device) => {
    return {
        type: actionTypes.FETCH_DASHBOARD_DONE,
        payload: device
    };
};

export const fetchDashboardFailed = (error) => {
    return {
        type: actionTypes.FETCH_DASHBOARD_FAIL,
        payload: error
    };
};

export const bookmarksUpdated = (bookmarks) => {
    return {
        type: actionTypes.BOOKMARKS_UPDATED,
        payload: bookmarks
    };
};
