import { FETCH_ASSETS_FAIL } from '../../assets/actions/actionTypes';
import { FETCH_DEVICE_DETAILS_FAIL } from '../../deviceDetails/actions/actionTypes';
import { FETCH_LICENSE_FORMS_FAIL } from '../../licenses/actions/actionTypes';
import { ACTIVATE_DEVICE_FAIL, DEACTIVATE_DEVICE_FAIL, FETCH_ORDER_DETAILS_FAIL } from '../../orderActivations/actions/actionTypes';
import { FETCH_MODAL_CONTENT_FAIL, FETCH_SOFTWARE_FAIL } from '../../softwareDownloads/actions/actionTypes';
import { PROFILE_LOAD_FAIL, PROFILE_SAVE_FAIL } from '../../userProfile/actions/actionTypes';
import * as actionTypes from '../actions/actionTypes';

const initialState = {
    display: false,
    type: 'info', // types: info, error, success
    title: 'Pluribus Networks Cloud',
    message: '',
    buttons: [{ label: 'OK', value: 'ok', variant: 'primary' }]
};

const errorModalState = {
    display: true,
    type: 'error',
    title: 'Pluribus Networks Cloud',
    buttons: [{ label: 'OK', value: 'ok', variant: 'danger' }]
};

export default (state = initialState, action) => {
    switch (action.type) {
    case actionTypes.PNC_MODAL_SHOW:
        return { ...state, display: true, ...action.payload };
    case actionTypes.PNC_MODAL_DISMISS:
        return { ...state, display: false };

    // Actions from other modules, which display an error modal
    case FETCH_SOFTWARE_FAIL:
        return { ...errorModalState, title: 'Software library unavailable', message: action.payload };
    case FETCH_ASSETS_FAIL:
        return { ...errorModalState, title: 'Failed to load hardware assets', message: action.payload };
    case FETCH_MODAL_CONTENT_FAIL:
        return { ...errorModalState, message: action.payload };
    case PROFILE_LOAD_FAIL:
        return { ...errorModalState, title: 'Failed to load user profile', message: action.payload };
    case PROFILE_SAVE_FAIL:
        return { ...errorModalState, title: 'Failed to save user profile', message: action.payload };
    case FETCH_ORDER_DETAILS_FAIL:
        return { ...errorModalState, title: 'Failed to load license data', message: action.payload };
    case ACTIVATE_DEVICE_FAIL:
        return { ...errorModalState, title: 'Failed to activate device', message: action.payload };
    case DEACTIVATE_DEVICE_FAIL:
        return { ...errorModalState, title: 'Failed to deactivate device', message: action.payload };
    case FETCH_DEVICE_DETAILS_FAIL:
        return { ...errorModalState, title: 'Failed to load device details', message: action.payload };
    case FETCH_LICENSE_FORMS_FAIL:
        return { ...errorModalState, title: 'Failed to load license data', message: action.payload };
    default:
        return state;
    }
};
