import PropTypes from 'prop-types';
import React from 'react';
import Loader from 'react-loader-spinner';

import styles from './Spinner.module.css';

const Spinner = (props) => (
    <div>
        <Loader type="Puff" color="#8FC248" height={40} width={40} className={styles.spinner}/>
        <strong>{props.message || 'Loading data, please wait...'}</strong>
    </div>
);

Spinner.propTypes = {
    message: PropTypes.string
};

export default Spinner;
