import * as actionTypes from './actionTypes';

export const userProfileLoadInitialized = () => {
    return {
        type: actionTypes.PROFILE_LOAD_INIT,
        loading: true
    };
};

export const userProfileLoadSucceeded = (userProfile) => {
    return {
        type: actionTypes.PROFILE_LOAD_DONE,
        payload: userProfile,
        loading: false
    };
};

export const userProfileLoadFailed = (error) => {
    return {
        type: actionTypes.PROFILE_LOAD_FAIL,
        payload: error,
        loading: false
    };
};

export const userProfileSaveInitialized = () => {
    return {
        type: actionTypes.PROFILE_SAVE_INIT,
        loading: true
    };
};

export const userProfileSaveSucceeded = (userProfile) => {
    return {
        type: actionTypes.PROFILE_SAVE_DONE,
        payload: userProfile,
        loading: false
    };
};

export const userProfileSaveFailed = (error) => {
    return {
        type: actionTypes.PROFILE_SAVE_FAIL,
        payload: error,
        loading: false
    };
};

export const userAccountDeleteInitialized = () => {
    return {
        type: actionTypes.PROFILE_DELETE_INIT,
        loading: true
    };
};

export const userAccountDeleteSucceeded = () => {
    return {
        type: actionTypes.PROFILE_DELETE_DONE,
        loading: false
    };
};

export const userAccountDeleteFailed = (error) => {
    return {
        type: actionTypes.PROFILE_DELETE_FAIL,
        payload: error,
        loading: false
    };
};

export const userAccountUpdateInitialized = () => {
    return {
        type: actionTypes.ACCOUNT_UPDATE_INIT,
        loading: true
    };
};

export const userAccountUpdateSucceeded = () => {
    return {
        type: actionTypes.ACCOUNT_UPDATE_DONE
    };
};

export const userAccountUpdateFailed = (error) => {
    return {
        type: actionTypes.ACCOUNT_UPDATE_FAIL,
        payload: error,
        loading: false
    };
};
