import * as actionTypes from './actionTypes';

export const fetchOrderDetailsInitialized = () => {
    return {
        type: actionTypes.FETCH_ORDER_DETAILS_INIT
    };
};

export const fetchOrderDetailsSucceeded = (orderDetails) => {
    return {
        type: actionTypes.FETCH_ORDER_DETAILS_DONE,
        payload: orderDetails
    };
};

export const fetchOrderDetailsFailed = (error) => {
    return {
        type: actionTypes.FETCH_ORDER_DETAILS_FAIL,
        payload: error
    };
};

export const activateDeviceInitialized = () => {
    return {
        type: actionTypes.ACTIVATE_DEVICE_INIT
    };
};

export const activateDeviceSucceeded = (payload) => {
    return {
        type: actionTypes.ACTIVATE_DEVICE_DONE,
        payload: payload
    };
};

export const activateDeviceFailed = (error) => {
    return {
        type: actionTypes.ACTIVATE_DEVICE_FAIL,
        payload: error
    };
};

export const deactivateDeviceInitialized = () => {
    return {
        type: actionTypes.DEACTIVATE_DEVICE_INIT
    };
};

export const deactivateDeviceSucceeded = (payload) => {
    return {
        type: actionTypes.DEACTIVATE_DEVICE_DONE,
        payload: payload
    };
};

export const deactivateDeviceFailed = (error) => {
    return {
        type: actionTypes.DEACTIVATE_DEVICE_FAIL,
        payload: error
    };
};
