import * as actionTypes from './actionTypes';

export const fetchDeviceDetailsInitialized = () => {
    return {
        type: actionTypes.FETCH_DEVICE_DETAILS_INIT
    };
};

export const fetchDeviceDetailsSucceeded = (device) => {
    return {
        type: actionTypes.FETCH_DEVICE_DETAILS_DONE,
        payload: device
    };
};

export const fetchDeviceDetailsFailed = (error) => {
    return {
        type: actionTypes.FETCH_DEVICE_DETAILS_FAIL,
        payload: error
    };
};

export const licenseUpdated = (updatedLicense) => {
    return {
        type: actionTypes.UPDATE_LICENSE,
        payload: updatedLicense
    };
};
