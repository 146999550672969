import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';

import { processFetchDashboardRequest } from '../../state/dashboard/dispatchers';
import permissionHelper from '../../utils/permission-helper';
import Assets from '../Assets';
import LicenseList from '../LicenseList';
import SoftwareDownloads from '../SoftwareDownloads';
import UserAgreements from '../UserAgreements';
import WelcomeModal from '../WelcomeModal';

class Dashboard extends React.Component {
    constructor(props) {
        super(props);
        const { user, showAgreements, showWelcomeModal } = props;
        const isUserImpersonated = user.real_user !== user.username;
        this.state = {
            showAgreementsModal: showAgreements && !isUserImpersonated,
            showWelcomeModal: showWelcomeModal && !isUserImpersonated
        };
    }

    renderBookmarkedSoftware() {
        return (
            <>
                <div className='pn-section-header pn-green' >Favorite Downloads</div>
                <SoftwareDownloads bookmarkedOnly={true} showCurrent={true}/>
                <SoftwareDownloads bookmarkedOnly={true} showCurrent={false}/>
                <div>You can bookmark software downloads shown in this screen from the <a href="/software">software page</a>.</div>
            </>
        );
    }

    render() {
        const { user, retrieved, processFetchDashboardRequest } = this.props;
        const hasAccess = permissionHelper(user);
        if (!hasAccess('dashboard')) {
            return (
                <>
                    <h3>
                        Welcome to Pluribus Networks.
                    </h3>
                    <div className='pn-green'>Please select action from the side menu.</div>
                </>
            );
        } else {
            if (!retrieved) processFetchDashboardRequest();
            return (
                <>
                    <UserAgreements show={this.state.showAgreementsModal} />
                    <WelcomeModal show={this.state.showWelcomeModal} />
                    <LicenseList licenses={this.props.licenses} title="Licenses about to expire" />
                    <LicenseList licenses={this.props.licenses} title="Licenses recently expired" expiredIn={30}/>
                    { hasAccess('softwareDownloads') && this.renderBookmarkedSoftware()}
                    { hasAccess('devices') && <Assets shortList={true}/>}
                </>
            );
        }
    }
}

const mapStateToProps = (state) => ({
    user: state.auth.user,
    showAgreements: state.auth.showAgreements,
    showWelcomeModal: state.auth.showWelcomeModal,
    retrieved: state.dashboard.retrieved,
    licenses: state.dashboard.expiringLicenses
});

const mapDispatchToProps = {
    processFetchDashboardRequest
};

Dashboard.propTypes = {
    user: PropTypes.object,
    showAgreements: PropTypes.bool,
    showWelcomeModal: PropTypes.bool,
    processFetchDashboardRequest: PropTypes.func,
    retrieved: PropTypes.bool,
    licenses: PropTypes.array
};

export default connect(mapStateToProps, mapDispatchToProps)(Dashboard);
