import PropTypes from 'prop-types';
import React from 'react';
import Dropdown from 'react-bootstrap/Dropdown';
import { connect } from 'react-redux';
import { Link, Redirect } from 'react-router-dom';

import styles from './HeaderMenu.module.css';

export class HeaderMenu extends React.Component {
    renderHeader() {
        return (
            <>
                <div className="txt-right"><b>Welcome </b></div>
                <span>{this.props.user.full_name} </span>
            </>
        );
    }

    stopImpersonate(e) {
        e.stopPropagation();
        localStorage.removeItem('currentUser');
        return <Redirect to='/users/impersonate/stop/?next=/admin/accounts/clouduser/'/>;
    }

    renderImpersonatedHeader() {
        const location = '/users/impersonate/stop/?next=/admin/accounts/clouduser/';
        return (
            <>
                <div className="txt-right">{this.props.user.real_user} <b>impersonating </b>
                    <a href={location} onClick={(e) => this.stopImpersonate(e)}>
                        <i className="fa fa-ban pn-red" title="Stop impersonating"/>
                    </a>
                </div>
                <span>{this.props.user.full_name} </span>
            </>
        );
    }

    render() {
        if (!this.props.user) return null;

        const HeaderMenuCustomToggle = React.forwardRef(({ onClick }, ref) => (
            <div style={{ cursor: 'pointer' }} ref={ref} onClick={e => { onClick(e); }}>
                <div id="userMenu" className="white txt-right">
                    {this.props.user.real_user === this.props.user.username ? this.renderHeader() : this.renderImpersonatedHeader()}
                    <i className="fa fa-lg fa-user"/>
                </div>
            </div>
        ));
        HeaderMenuCustomToggle.displayName = 'HeaderMenuCustomToggle';

        return (
            <Dropdown>
                <Dropdown.Toggle as={HeaderMenuCustomToggle}>
                </Dropdown.Toggle>

                <Dropdown.Menu className={styles.rightMenu}>
                    {this.props.user.is_staff && <Dropdown.Item href="/admin/">PNC Admin</Dropdown.Item>}
                    <Dropdown.Item as={Link} to="/user/profile">My Profile</Dropdown.Item>
                    {this.props.user.salesforce && <Dropdown.Item href="https://pluribusnetworks.secure.force.com/ecsp/ForgotPassword">Change Password</Dropdown.Item>}
                    <Dropdown.Item as={Link} to="/user/logout" >Log out</Dropdown.Item>
                </Dropdown.Menu>
            </Dropdown>
        );
    }
}

const mapStateToProps = (state) => ({
    user: state.auth.user
});

HeaderMenu.propTypes = {
    user: PropTypes.object,
    children: PropTypes.any,
    onClick: PropTypes.func
};

export default connect(mapStateToProps)(HeaderMenu);
