import * as actionTypes from '../actions/actionTypes';

const initialState = {
    data: null,
    loading: false
};

export default (state = initialState, action) => {
    switch (action.type) {
    case actionTypes.PROFILE_LOAD_INIT:
        return { ...state, loading: true };
    case actionTypes.PROFILE_LOAD_DONE:
        return { ...state, data: action.payload, loading: false };
    case actionTypes.PROFILE_LOAD_FAIL:
        return { ...state, loading: false };
    case actionTypes.PROFILE_SAVE_INIT:
        return { ...state, loading: true };
    case actionTypes.PROFILE_SAVE_DONE:
        return { ...state, data: action.payload, loading: false };
    case actionTypes.PROFILE_SAVE_FAIL:
        return { ...state, loading: false };
    default:
        return state;
    }
};
