import { store } from '../index';
import { pncModalShow } from '../state/pncmodal/actions/actionCreators';

class PNCModalUtil {
    static defaultTitle = 'Pluribus Networks Cloud';
    static defaultButtons = [{ label: 'OK', value: 'ok', variant: 'primary' }];
    static defaultErrorButtons = [{ label: 'OK', value: 'ok', variant: 'danger' }];

    static info(message, title = this.defaultTitle, buttons = this.defaultButtons) {
        store.dispatch(pncModalShow({ title, message, buttons, type: 'info' }));
    }

    static error(message, title = this.defaultTitle, buttons = this.defaultErrorButtons) {
        store.dispatch(pncModalShow({ title, message, buttons, type: 'error' }));
    }

    static success(message, title = this.defaultTitle, buttons = this.defaultButtons) {
        store.dispatch(pncModalShow({ title, message, buttons, type: 'success' }));
    }
}

export { PNCModalUtil as default, PNCModalUtil as pncmodal };
