import * as actionTypes from './actionTypes';

export const fetchLicenseFormsInitialized = () => {
    return {
        type: actionTypes.FETCH_LICENSE_FORMS_INIT
    };
};

export const fetchLicenseFormsSucceeded = (licenseForms) => {
    return {
        type: actionTypes.FETCH_LICENSE_FORMS_DONE,
        payload: licenseForms
    };
};

export const fetchLicenseFormsFailed = (error) => {
    return {
        type: actionTypes.FETCH_LICENSE_FORMS_FAIL,
        payload: error
    };
};
