import PropTypes from 'prop-types';
import React from 'react';
import { Button, FormControl, InputGroup, Modal } from 'react-bootstrap';
import { CopyToClipboard } from 'react-copy-to-clipboard';

import styles from './LicenseKeyModal.module.css';

export class LicenseKeyModal extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            copied: false
        };

        this.onCopyHandler = this.onCopyHandler.bind(this);
    }

    dismissModal() {
        this.props.onDismiss && this.props.onDismiss();
    }

    onCopyHandler() {
        const setState = this.setState.bind(this);
        setState({ copied: true });
        setTimeout(() => setState({ copied: false }), 1000);
    }

    render() {
        return (
            <Modal size="lg" show={Boolean(this.props.keyValue)} onHide={() => this.dismissModal()}>
                <Modal.Header closeButton>
                    <Modal.Title className='h6'>License Key</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="mb-3">The following license key can be installed
                        into UNUM or your NetvisorOS/ONVL device.</div>

                    <InputGroup className="mb-3">
                        <FormControl className={styles.licenseInput} readOnly value={this.props.keyValue || ''} />
                        <InputGroup.Append>
                            <CopyToClipboard className='copy-button' text={this.props.keyValue}
                                                 onCopy={this.onCopyHandler}>
                                {
                                        this.state.copied
                                            ? <Button size='sm'><i className='fa fa-check'/></Button>
                                            : <Button size='sm'><i className='fa fa-copy'/></Button>
                                    }
                            </CopyToClipboard>
                        </InputGroup.Append>
                    </InputGroup>

                </Modal.Body>
                <Modal.Footer>
                    <Button className="btn pn-bg-color pn-white" onClick={() => this.dismissModal()} >Close</Button>
                </Modal.Footer>
            </Modal>
        );
    }
}

LicenseKeyModal.propTypes = {
    keyValue: PropTypes.string,
    onDismiss: PropTypes.func
};

export default LicenseKeyModal;
