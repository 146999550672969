import './App.css';
import 'react-toastify/dist/ReactToastify.css';
import 'react-loader-spinner/dist/loader/css/react-spinner-loader.css';

import axios from 'axios';
import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { BrowserRouter as Router, Redirect, Route, Switch } from 'react-router-dom';
import { toast } from 'react-toastify';

import { getUserData, processLogoutRequest } from '../../state/auth/dispatchers';
import permissionHelper from '../../utils/permission-helper';
import Assets from '../Assets';
import PNCModal from '../Common/PNCModal';
import Dashboard from '../Dashboard';
import DeviceDetails from '../DeviceDetails';
import ErrorBoundary from '../ErrorBoundary';
import LicenseCreate from '../LicenseCreate';
import LicenseHistory from '../LicenseHistory';
import Login from '../Login';
import Logout from '../Logout';
import NavBar from '../NavBar';
import OrderActivations from '../OrderActivations';
import SideBar from '../SideBar';
import SoftwareDownloads from '../SoftwareDownloads';
import UserProfile from '../UserProfile';
import NoMatch from '../NoMatch/NoMatch';

toast.configure({ autoClose: false });

class App extends React.Component {
    componentDidMount() {
        if (!this.props.user) this.props.getUserData();
        // Add Axios interceptors to handle backend session timeouts
        axios.interceptors.response.use(response => {
            // Clear session timeout clock on every successful request to the backend
            clearTimeout(this.sessionTimerId);

            // Start a session countdown unless this was a logout (DELETE /api/login) action
            const isLogout = (response.config.url === '/api/login' && response.config.method === 'delete');
            if (!isLogout && this.props.user) {
                this.sessionTimerId = this.startSessionTimer();
            }
            return response;
        }, error => {
            // Catch 403 errors when user session does expire
            if (error.response.status === 403) {
                this.handleSessionTimeout();
            } else {
                return Promise.reject(error);
            }
        });
    }

    startSessionTimer() {
        const sessionDuration = this.props.user.session_timeout * 1000;
        return setTimeout(this.handleSessionTimeout.bind(this), sessionDuration);
    }

    handleSessionTimeout() {
        toast.warn('Your session expired. Please log in again...',
            { toastId: 'session_expired', autoClose: 5000 });
        this.props.processLogoutRequest();
    }

    render() {
        const { user } = this.props;
        const hasAccess = permissionHelper(user);

        const PrivateRoute = ({ component: Component, passedProps, permission, ...routeProps }) => (
            <Route {...routeProps} render={props => {
                if (!user) return <Redirect to='/user/login' />;
                if ((permission && hasAccess(permission)) || !permission) return <Component {...props} {...passedProps} />;
            }
            }/>
        );

        return (
            <ErrorBoundary>
                <div className="App">
                    <PNCModal/>
                    <Router>
                        <NavBar user={user}/>
                        <div id="main">
                            <Route exact path="/">
                                {user ? <Redirect to="/dashboard" /> : <Redirect to="/user/login" />}
                            </Route>
                            <Route exact path="/orders/details">
                                <Redirect to="/orders/activations" />
                            </Route>
                            <Route exact path="/software/index">
                                <Redirect to="/software" />
                            </Route>
                            <Route path="/user/login" component={Login} />
                            <Route path="/user/logout" component={Logout} />
                            <div>
                                <div className="clearfix"></div>
                                <SideBar user={user}/>
                                <div id="content">
                                    <Switch>
                                        <PrivateRoute path="/dashboard" component={Dashboard} />
                                        <PrivateRoute path="/devices" component={Assets} permission="devices"/>
                                        <PrivateRoute path="/device/:deviceId" component={DeviceDetails} permission="devices" />
                                        <PrivateRoute path="/user/profile" component={UserProfile} />
                                        <PrivateRoute exact path="/software" component={SoftwareDownloads} permission='softwareDownloads' passedProps={{ showCurrent: true }}/>
                                        <PrivateRoute exact path="/software/archive" component={SoftwareDownloads} permission='softwareDownloads' passedProps={{ showCurrent: false }}/>
                                        <PrivateRoute exact path="/orders/activations" component={OrderActivations} permission='licenseActivation'/>
                                        <PrivateRoute exact path="/license/archive" component={LicenseHistory} permission='licensePortal'/>
                                        <PrivateRoute exact path="/license/create" component={LicenseCreate} permission='licensePortal' />
                                        {/* Don't display a 404 message (NoMatch) component on /user/login and /user/logout pages */}
                                        <Route path="/user/:login" />
                                        <PrivateRoute component={NoMatch} />
                                    </Switch>
                                </div>
                            </div>
                        </div>
                    </Router>
                </div>
            </ErrorBoundary>

        );
    }
}

const mapStateToProps = (state) => ({
    user: state.auth.user
});

const mapDispatchToProps = {
    getUserData,
    processLogoutRequest
};

App.propTypes = {
    user: PropTypes.object,
    getUserData: PropTypes.func,
    processLogoutRequest: PropTypes.func
};

export default connect(mapStateToProps, mapDispatchToProps)(App);
