import * as actionTypes from './actionTypes';

export const fetchSoftwareInitialized = () => {
    return {
        type: actionTypes.FETCH_SOFTWARE_INIT
    };
};

export const fetchSoftwareSucceeded = (software) => {
    return {
        type: actionTypes.FETCH_SOFTWARE_DONE,
        payload: software
    };
};

export const fetchSoftwareFailed = (error) => {
    return {
        type: actionTypes.FETCH_SOFTWARE_FAIL,
        payload: error
    };
};

export const fetchModalContentInitialized = () => {
    return {
        type: actionTypes.FETCH_MODAL_CONTENT_INIT
    };
};

export const fetchModalContentSucceeded = (content) => {
    return {
        type: actionTypes.FETCH_MODAL_CONTENT_DONE,
        payload: content
    };
};

export const fetchModalContentFailed = (error) => {
    return {
        type: actionTypes.FETCH_MODAL_CONTENT_FAIL,
        payload: error
    };
};


export const AcceptEulaInitialized = () => {
    return {
        type: actionTypes.ACCEPT_EULA_INIT
    };
};

export const acceptEulaSucceeded = (productId) => {
    return {
        type: actionTypes.ACCEPT_EULA_DONE,
        payload: productId
    };
};

export const acceptEulaFailed = (error) => {
    return {
        type: actionTypes.ACCEPT_EULA_FAIL,
        payload: error
    };
};
