import PropTypes from 'prop-types';
import React from 'react';
import { Button, Form, Modal } from 'react-bootstrap';
import { connect } from 'react-redux';

import { disableWelcomeScreen } from '../../state/userProfile/dispatchers';

export class WelcomeModal extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            isDisplayed: this.props.show,
            dontShowWelcome: true
        };
    }

    handleCheckbox(e, name) {
        this.setState({ [name]: !this.state[name] });
    }

    handleWelcomeModal() {
        this.state.dontShowWelcome && this.props.disableWelcomeScreen();
        this.setState({ isDisplayed: false });
    }

    render() {
        return (
            <Modal size="lg" show={this.state.isDisplayed}>
                <Modal.Body>
                    <div className="pn-font-lg pn-gray pn-font" style={{ paddingBottom: 20 }}>
                        Welcome to PN Cloud
                    </div>
                    <div className="pn-font-s2 pn-gray" style={{ paddingBottom: 20 }}>
                        PN Cloud provides you with a one-stop web portal to access your purchased assets.
                    </div>
                    <div className="row">
                        <div className="col-md-7">
                            <div className="pn-section-header2 pn-color">
                                ACTIVATE ASSETS
                            </div>
                            <div className="pn-font-s3 pn-gray">
                                Go to ACTIVATIONS in the PN Cloud navigation
                                menu. Enter the device identifier and click on the
                                Activate button. The Activation status will be
                                shown next. If successful, your device is now
                                ready to have software installed on it.
                            </div>
                            <div className="pn-section-header2 pn-color">
                                DOWNLOAD SOFTWARE
                            </div>
                            <div className="pn-font-s3 pn-gray">
                                Click on the DOWNLOAD button to download the latest
                                software for your activated assets.
                            </div>
                        </div>
                        <div className="col-md-4 offset-md-1" >
                            <img className="center-block vcenter" src="../images/pncloud_menu.png" alt="" style={{ width: 150 + 'px' }} />

                            <Form.Group style={{ paddingTop: 10 + 'px' }}>
                                <Form.Check
                                    checked={this.state.dontShowWelcome}
                                    type="checkbox"
                                    name="dontShowWelcome"
                                    onChange={(e) => this.handleCheckbox(e, 'dontShowWelcome')}
                                    label={<label>Don&apos;t show me this again.</label> }
                                />
                            </Form.Group>
                        </div>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button className="btn pn-bg-color pn-white" onClick={() => this.handleWelcomeModal()} >Got it!</Button>
                </Modal.Footer>
            </Modal>
        );
    }
}

const mapDispatchToProps = {
    disableWelcomeScreen
};

WelcomeModal.propTypes = {
    show: PropTypes.bool,
    disableWelcomeScreen: PropTypes.func
};

export default connect(null, mapDispatchToProps)(WelcomeModal);
