import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';

import { deleteUserAccount } from '../../state/userProfile/dispatchers';

class DeactivateUser extends React.Component {
    handleDeleteAccount(e) {
        e.preventDefault();
        if (window.confirm('Are you sure you want to delete your account?')) {
            this.props.deleteUserAccount();
        }
    }

    render() {
        return (
            <>
                <div className="pn-section-header pn-color">DEACTIVATE MY ACCOUNT</div>
                <div>
                    <p>
                        By deactivating your account, you will lose all access to Pluribus Networks Cloud, as well as any data that you might have stored here.
                        If you have questions, please <a href="https://www.pluribusnetworks.com/contact-us/" target="_blank" rel="noopener noreferrer">contact us</a> before deactivating your account.
                    </p>
                    <p>
                        <button onClick={ (e) => { this.handleDeleteAccount(e); }} className="btn btn-danger">Deactivate my account</button>
                    </p>
                </div>
                <hr/>
            </>
        );
    }
}

const mapDispatchToProps = {
    deleteUserAccount
};

DeactivateUser.propTypes = {
    deleteUserAccount: PropTypes.func
};

export default connect(null, mapDispatchToProps)(DeactivateUser);
