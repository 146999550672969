import PropTypes from 'prop-types';
import React from 'react';
import { Button, Form, Modal } from 'react-bootstrap';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';

import { sendAgreementsToServer } from '../../state/auth/dispatchers';

export class UserAgreements extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            isDisplayed: this.props.show,
            privacyPolicyAgreement: false,
            termsOfServiceAgreement: false,
            marketingCorrespondenceAgreement: false
        };
    }

    handleCheckbox(e, name) {
        this.setState({ [name]: !this.state[name] });
    }

    handleAgreements() {
        this.props.sendAgreementsToServer({ agreement: this.state });
        this.setState({ isDisplayed: false });
    }

    render() {
        const isDisabled = !this.state.termsOfServiceAgreement || !this.state.privacyPolicyAgreement;

        return (
            <Modal show={this.state.isDisplayed}>
                <Modal.Header>
                    <Modal.Title className="h6">Our Terms of Use and Privacy Policy have changed.</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="mb10">
                        We have updated our Terms of Use and Privacy Policy. Before logging into your account, please review and accept our updated terms.
                    </div>
                    <Form.Group>
                        <Form.Check
                            value={this.state.termsOfServiceAgreement}
                            type="checkbox"
                            name="termsOfServiceAgreement"
                            onChange={(e) => this.handleCheckbox(e, 'termsOfServiceAgreement')}
                            label={<label>
                                <b>I have read and agree to the Pluribus Networks, Inc. </b>
                                <a href="http://www.pluribusnetworks.com/terms/" target='_blank' rel="noopener noreferrer">Terms of Service</a>
                            </label> }
                        />
                        <Form.Check
                            value={this.state.privacyPolicyAgreement}
                            type="checkbox"
                            name="privacyPolicyAgreement"
                            onChange={(e) => this.handleCheckbox(e, 'privacyPolicyAgreement')}
                            label={<label>
                                <b>I have read and agree to the Pluribus Networks, Inc. </b>
                                <a href="http://www.pluribusnetworks.com/privacy/" target='_blank' rel="noopener noreferrer">Privacy Policy</a>
                            </label> }
                        />
                        <Form.Check
                            value={this.state.marketingCorrespondenceAgreement}
                            type="checkbox"
                            name="marketingCorrespondenceAgreement"
                            onChange={(e) => this.handleCheckbox(e, 'marketingCorrespondenceAgreement')}
                            label="(Optional) I want to receive occasional marketing emails related to my Pluribus Networks Cloud account."
                        />
                    </Form.Group>
                    <div>
                        Note that this is separate from your Pluribus Customer Portal account, which you can access via Salesforce.
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="success" onClick={() => this.handleAgreements()} disabled={isDisabled}>I agree</Button>
                    <Link to="/user/logout">
                        <Button variant="danger">Log out</Button>
                    </Link>
                </Modal.Footer>
            </Modal>
        );
    }
}

const mapDispatchToProps = {
    sendAgreementsToServer
};

UserAgreements.propTypes = {
    show: PropTypes.bool,
    sendAgreementsToServer: PropTypes.func
};

export default connect(null, mapDispatchToProps)(UserAgreements);
