import axios from 'axios';
import PropTypes from 'prop-types';
import React from 'react';
import { Badge, Button, Card } from 'react-bootstrap';
import { connect } from 'react-redux';
import ReactRouterPropTypes from 'react-router-prop-types';

import { processFetchDeviceDetailsRequest, updateLicense } from '../../state/deviceDetails/dispatchers';
import log from '../../utils/logger';
import pncmodal from '../../utils/pncmodal';
import Spinner from '../Common/Spinner';
import LicenseKeyModal from '../modals/LicenseKeyModal';

import styles from './DeviceDetails.module.css';

class DeviceDetails extends React.Component {
    constructor(props) {
        super(props);
        this.state = { licenseKey: null };
    }

    componentDidMount() {
        const { deviceId } = this.props.match.params;
        this.props.processFetchDeviceDetailsRequest(deviceId);
    }

    displayLicenseKey(licenseKey) {
        this.setState({ licenseKey });
    }

    createLicenseKey(license, index) {
        const payload = {
            license:
            {
                serial: license.serial,
                code: license.code,
                timestamp: new Date().toLocaleString('sv') // YYYY-MM-DD HH:MM:SS
            }
        };
        axios.post('/api/licenses', payload
        ).then(response => {
            const licenseKey = response.data.license.key;
            this.setState({ licenseKey }, this.props.updateLicense({ licenseKey, index }));
        }).catch(error => {
            log.error('Failed to create license.', error);
            pncmodal.error(error, 'Failed to create license');
        });
    }

    getDeviceImagePath(hwPid) {
        if (!hwPid) return null;
        if (hwPid.includes('F64')) return '../images/devices/f64-back.jpg';
        if (hwPid.includes('E68')) return '../images/devices/leo-front.jpg';
        if (hwPid.includes('S6000')) return '../images/devices/dell_s6000.png';
        if (hwPid.includes('S4048')) return '../images/devices/dell_s4048.png';
        return null;
    }

    getStatusBadge(license) {
        if (license.installed) return <Badge variant="success">Installed</Badge>;
        if (license.key) return <Badge variant="primary">Activated</Badge>;
        return <Badge variant="secondary">New</Badge>;
    }

    renderDeviceInfo(device) {
        const deviceImageUrl = this.getDeviceImagePath(device.hwPid);
        return (
            <Card>
                <Card.Header>Switch details</Card.Header>
                <Card.Body>
                    {deviceImageUrl && <img src={deviceImageUrl} id={styles.switchImage} alt="switch"/>}
                    <ul className="list-group left">
                        <li className={styles.listItem}><span className="switch-item-label">Account Name:</span> <b>{device.accountName}</b></li>
                        <li className={styles.listItem}><span className="switch-item-label">Account Contact:</span> <b>{device.contactName}</b></li>
                        <li className={styles.listItem}><span className="switch-item-label">Part Number:</span> <b>{device.partNumber}</b></li>
                        <li className={styles.listItem}><span className="switch-item-label">Serial Number:</span> <b>{device.serialNumber}</b></li>
                        <li className={styles.listItem}><span className="switch-item-label">Hostname:</span> <b>{device.hostname}</b></li>
                        <li className={styles.listItem}><span className="switch-item-label">IP Address:</span> <b>{device.ip}{device.natip ? `${'\n'}(${device.natip} behind NAT)` : ''}</b></li>
                        <li className={styles.listItem}><span className="switch-item-label">Software Version :</span> <b>{device.softwareVersion}</b></li>
                        <li className={styles.listItem}><span className="switch-item-label">Last Connect:</span> <b>{device.lastConnect && new Date(device.lastConnect).toLocaleString()}</b></li>
                        <li className={styles.listItem}><span className="switch-item-label">Last Fault:</span> <b>{device.lastCoreTime && new Date(device.lastCoreTime).toLocaleString()}</b></li>
                    </ul>
                </Card.Body>
            </Card>
        );
    }

    renderLicenses(device) {
        const displayNoLicensesMessage = (!device.alerts.length && !device.licenses.length);
        return (
            <Card>
                <Card.Header>Software licences</Card.Header>
                <Card.Body>
                    {device.alerts && device.alerts.map((alert, index) => (
                        <div key={index} className="alert alert-warning" role="alert">
                            {alert}
                        </div>)
                    )}

                    {displayNoLicensesMessage && <div className="alert alert-info" role="alert">
                        No licenses found.
                    </div>}

                    <ul className="list-group left">
                        {device.licenses.map((license, index) => (
                            <li key={index} className={styles.listItem}>
                                <div>{license.swpid.code} {this.getStatusBadge(license)}</div>
                                <div className="font-sm">{license.swpid.description}</div>
                                <div id={styles.buttons}>
                                    { !license.key && <Button size="sm" variant="primary" onClick={() => this.createLicenseKey(license, index)}>Activate license key</Button>}
                                    { license.key && <Button size="sm" variant="primary" onClick={() => this.displayLicenseKey(license.key)}>Show license key</Button>}
                                </div>
                            </li>
                            )
                        )}
                    </ul>
                </Card.Body>
            </Card>
        );
    }

    render() {
        const { device, loading } = this.props;

        if (loading) {
            return <Spinner />;
        } else {
            return (
                <div className='row'>
                    <div className='col-md-6'>
                        {device && this.renderDeviceInfo(device)}
                    </div>
                    <div className='col-md-6'>
                        {device && this.renderLicenses(device)}
                    </div>
                    <LicenseKeyModal keyValue={this.state.licenseKey} onDismiss={() => this.setState({ licenseKey: null })} />
                </div>
            );
        }
    }
}

const mapStateToProps = (state) => ({
    device: state.deviceDetails.device,
    loading: state.deviceDetails.loading
});

const mapDispatchToProps = {
    processFetchDeviceDetailsRequest,
    updateLicense
};

DeviceDetails.propTypes = {
    device: PropTypes.object,
    loading: PropTypes.bool,
    processFetchDeviceDetailsRequest: PropTypes.func,
    updateLicense: PropTypes.func,
    history: ReactRouterPropTypes.history,
    match: ReactRouterPropTypes.match
};

export default connect(mapStateToProps, mapDispatchToProps)(DeviceDetails);
