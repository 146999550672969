import axios from 'axios';
import PropTypes from 'prop-types';
import React from 'react';
import { Button, Card, FormControl, InputGroup } from 'react-bootstrap';
import { CSVLink } from 'react-csv';
import { withRouter } from 'react-router';
import { toast } from 'react-toastify';

import styles from './OrderActivations.module.css';

class DeviceActivations extends React.Component {
    constructor(props) {
        super(props);
        this.state = { newActivationDeviceId: '' };
    }

    handleDeviceIdChange(event) {
        this.setState({ newActivationDeviceId: event.target.value });
    }

    getLicenseExpirationMessage(daysUntilLicenseExpires) {
        if (daysUntilLicenseExpires === null) return null;
        if (daysUntilLicenseExpires < 0) return 'License expired!';
        if (daysUntilLicenseExpires < 7) return 'License expires soon!';
        if (daysUntilLicenseExpires < 30) return 'License expires in ' + daysUntilLicenseExpires + ' days.';
    }

    isUnum(orderDetail) {
        const unumProductTypes = ['VCFC'];
        return unumProductTypes.includes(orderDetail.product_type);
    }

    showDevice(deviceId) {
        axios.get('/api/switches', { params: { deviceId: deviceId } }).then(response => {
            const serial = response?.data?.switches[0]?.serial;
            if (serial) this.props.history.push('/device/' + serial);
            else toast.warn('The switch has not connected.');
        }).catch(() => {
            toast.warn('The switch has not connected.');
        });
    }

    render() {
        const { orderDetail, activateDevice, deactivateDevice, displayLicenseKey } = this.props;
        const csvData = [{ id: orderDetail.product_name }, { id: orderDetail.sw_pid }, { id: orderDetail.auth_code }, ...orderDetail.order_activations];
        return (
            <Card.Body>
                <div className="row">
                    <div className="pn-green col-md-12">
                        {orderDetail.order_activations.length} of {orderDetail.quantity} licenses in use.
                        <br/>
                        {
                            orderDetail.order_activations.length === 0 &&
                                <p className="pn-grey">
                                    <i className="fa fa-icon fa-download pn-grey"/>
                                    Export licenses (CSV)
                                </p>
                        }
                        {
                            orderDetail.order_activations.length > 0 &&
                                <CSVLink data={csvData} filename={`${orderDetail.sw_pid}_licenses.csv`}>
                                    <i className="fa fa-icon fa-download pn-green"/>
                                    Export licenses (CSV)
                                </CSVLink>
                        }
                    </div>

                    {/* Existing device activations. */}
                    {orderDetail.order_activations.map((orderActivation, index) => {
                        const expiration = this.getLicenseExpirationMessage(orderActivation.expires_in_days);


                        return (
                            <div className="col-md-12" key={index} style={{ marginTop: 10 }}>
                                <InputGroup>
                                    <FormControl
                                        value={orderActivation.device_id}
                                        disabled
                                    />
                                    <InputGroup.Append>
                                        {expiration && <Button variant="warning" size="sm" disabled>
                                            {expiration}
                                        </Button>}
                                        {orderActivation.license_key
                                            // Show license key if it's available
                                            ? <Button variant="info" size="sm"
                                                    onClick={() => displayLicenseKey(orderActivation.license_key)}>
                                                License Key
                                            </Button>
                                            // Otherwise allow user to view device details
                                            : <Button variant="outline-info" size="sm"
                                                    onClick={() => this.showDevice(orderActivation.device_id)}>
                                                Show Device
                                            </Button>
                                        }
                                        <Button variant="secondary" size="sm" style={{ marginRight: 10 }}
                                                onClick={() => deactivateDevice(orderDetail, orderActivation)}>
                                            Deactivate
                                        </Button>
                                    </InputGroup.Append>
                                </InputGroup>
                            </div>
                        );
                    })}

                    {/* New device activation. */}
                    {orderDetail.quantity - orderDetail.order_activations.length > 0 && (
                        <div className="col-md-12" style={{ marginTop: 20 }}>
                            <div>
                                Activate
                                { orderDetail.order_activations.length ? ' another ' : ' ' }
                                { this.isUnum(orderDetail) ? 'UNUM' : 'device' }
                            </div>
                            <InputGroup>
                                <FormControl
                                    placeholder={ this.isUnum(orderDetail) ? 'Machine ID' : 'Device ID' }
                                    onChange={(e) => this.handleDeviceIdChange(e)}
                                />
                                <InputGroup.Append>
                                    <Button id={styles.activate} size="sm"
                                            onClick={() => activateDevice(orderDetail, this.state.newActivationDeviceId)}>
                                        Activate
                                    </Button>
                                </InputGroup.Append>
                            </InputGroup>
                        </div>
                    )}
                </div>
            </Card.Body>
        );
    }
}

DeviceActivations.propTypes = {
    activateDevice: PropTypes.func,
    deactivateDevice: PropTypes.func,
    displayLicenseKey: PropTypes.func,
    orderDetail: PropTypes.object,
    history: PropTypes.object.isRequired
};

export default withRouter(DeviceActivations);
