import React from 'react';

import DeactivateUser from './DeactivateUser';
import ExportUserData from './ExportUserData';
import UserAgreements from './UserAgreements';
import styles from './UserProfile.module.css';
import UserProfileForm from './UserProfileForm';

const UserProfile = () => {
    return (
        <div className={styles.userProfile} style={{ marginTop: 50 }}>
            <UserProfileForm/>
            <ExportUserData/>
            <UserAgreements/>
            <DeactivateUser/>
        </div>
    );
};

export default UserProfile;
