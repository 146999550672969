import axios from 'axios';

import { fetchDeviceDetailsFailed, fetchDeviceDetailsInitialized, fetchDeviceDetailsSucceeded, licenseUpdated } from '../actions/actionCreators';

export const processFetchDeviceDetailsRequest = (deviceId) => {
    return dispatch => {
        dispatch(fetchDeviceDetailsInitialized());

        axios.get(`/api/assets/hardware/${deviceId}`)
            .then(response => {
                if (response) dispatch(fetchDeviceDetailsSucceeded(response.data));
                else {
                    dispatch(fetchDeviceDetailsFailed(response.data));
                }
            })
            .catch(
                err => {
                    dispatch(fetchDeviceDetailsFailed(!err.response
                        ? 'Network Error' : err.response.statusText));
                }
            );
    };
};

export const updateLicense = (update) => {
    return dispatch => {
        dispatch(licenseUpdated(update));
    };
};
