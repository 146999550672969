/**
 * Create a function which checks if the user has access permission to a given area.
 *
 * Usage example:
 *   const hasAccess = permissionHelper(user);
 *   if(hasAccess('licenseActivation')) ...
 *
 * @param user - user data object
 * @returns {function(string):bool}
 */
const permissionHelper = (user) => {
    return (area) => {
        if (!user) return false;

        const permissions = user.permissions;
        const isSalesforceUser = user.salesforce;

        switch (area) {
        case 'dashboard':
            return permissions.includes('accounts.can_access_dashboard');
        case 'softwareDownloads':
            return permissions.includes('accounts.can_access_software');
        case 'licensePortal':
            return permissions.includes('accounts.can_access_license_portal');
        case 'licenseActivation':
            return permissions.includes('accounts.can_access_assets');
        case 'devices':
            return isSalesforceUser || permissions.includes('accounts.can_access_assets');
        case 'salesforceAssets':
            return isSalesforceUser || permissions.includes('accounts.can_access_assets_all');
        default:
            return false;
        }
    };
};

export default permissionHelper;
