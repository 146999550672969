export const LOGIN_INIT = 'LOGIN_INIT';
export const LOGIN_DONE = 'LOGIN_DONE';
export const LOGIN_FAIL = 'LOGIN_FAIL';
export const LOGIN_FORM_RESET = 'LOGIN_FORM_RESET';
export const LOGOUT_INIT = 'LOGOUT_INIT';
export const LOGOUT_DONE = 'LOGOUT_DONE';
export const LOGOUT_FAIL = 'LOGOUT_FAIL';
export const EULA_DONE = 'EULA_DONE';
export const EULA_FAIL = 'EULA_FAIL';
export const AGREEMENTS_FETCH_INIT = 'AGREEMENTS_FETCH_INIT';
export const AGREEMENTS_FETCH_DONE = 'AGREEMENTS_FETCH_DONE';
export const AGREEMENTS_FETCH_FAIL = 'AGREEMENTS_FETCH_FAIL';
