import * as actionTypes from './actionTypes';

export const pncModalShow = (modalConfig) => {
    return {
        type: actionTypes.PNC_MODAL_SHOW,
        payload: modalConfig
    };
};

export const pncModalDismiss = () => {
    return {
        type: actionTypes.PNC_MODAL_DISMISS
    };
};
