import PropTypes from 'prop-types';
import React from 'react';
import { Button, Col, Form, Row } from 'react-bootstrap';
import { connect } from 'react-redux';

import { loadUserProfile, saveUserProfile } from '../../state/userProfile/dispatchers';

class UserProfileForm extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            first_name: '',
            last_name: '',
            email: '',
            title: '',
            company_name: '',
            phone: '',
            city: '',
            state: '',
            zip_code: '',
            country: ''
        };
    }

    componentDidMount() {
        this.props.loadUserProfile();
    }

    componentDidUpdate(prevProps) {
        if (this.props.userProfile !== prevProps.userProfile) {
            this.setState(this.props.userProfile);
        }
    }

    handleInput(e, name) {
        this.setState({ [name]: e.target.value });
    }

    handleSaveUserProfile(e) {
        e.preventDefault();
        this.props.saveUserProfile(this.state);
    }


    render() {
        return (
            <>
                <div className="pn-section-header pn-color">User Profile</div>
                <Form>
                    <Row>
                        <Col md="6">
                            <Form.Label>First Name</Form.Label>
                            <Form.Control required value={this.state.first_name} onChange={(e) => this.handleInput(e, 'first_name')}/>
                        </Col>
                        <Col md="6">
                            <Form.Label>Last Name</Form.Label>
                            <Form.Control required value={this.state.last_name} onChange={(e) => this.handleInput(e, 'last_name')}/>
                        </Col>
                    </Row>
                    <Row>
                        <Col md="6">
                            <Form.Label>Email</Form.Label>
                            <Form.Control value={this.state.email} disabled/>
                            If you want to change your email, please <a href="https://www.pluribusnetworks.com/contact-us/" target="_blank" rel="noopener noreferrer">contact us</a>.
                        </Col>
                        <Col md="6">
                            <Form.Label>Job Title</Form.Label>
                            <Form.Control value={this.state.title} onChange={(e) => this.handleInput(e, 'title')}/>
                        </Col>
                    </Row>
                    <Row>
                        <Col md="6">
                            <Form.Label>Company</Form.Label>
                            <Form.Control required value={this.state.company_name} onChange={(e) => this.handleInput(e, 'company_name')}/>
                        </Col>
                        <Col md="6">
                            <Form.Label>Phone</Form.Label>
                            <Form.Control value={this.state.phone} onChange={(e) => this.handleInput(e, 'phone')}/>
                        </Col>
                    </Row>
                    <Row>
                        <Col md="6">
                            <Form.Label>City</Form.Label>
                            <Form.Control value={this.state.city} onChange={(e) => this.handleInput(e, 'city')}/>
                        </Col>
                        <Col md="6">
                            <Form.Label>State</Form.Label>
                            <Form.Control value={this.state.state} onChange={(e) => this.handleInput(e, 'state')}/>
                        </Col>
                    </Row>
                    <Row>
                        <Col md="6">
                            <Form.Label>Zip Code</Form.Label>
                            <Form.Control value={this.state.zip_code} onChange={(e) => this.handleInput(e, 'zip_code')}/>
                        </Col>
                        <Col md="6">
                            <Form.Label>Country</Form.Label>
                            <Form.Control required value={this.state.country} onChange={(e) => this.handleInput(e, 'country')}/>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <div style={{ marginTop: 20 }}>
                                <Button onClick={(e) => this.handleSaveUserProfile(e)} className="btn pn-bg-color pn-white">
                                    Update my personal data
                                </Button>
                            </div>
                        </Col>
                    </Row>
                </Form>
                <hr/>
            </>

        );
    }
}

const mapStateToProps = (state) => ({
    user: state.auth.user,
    userProfile: state.userProfile.data,
    loading: state.userProfile.loading
});

const mapDispatchToProps = {
    loadUserProfile,
    saveUserProfile
};

UserProfileForm.propTypes = {
    user: PropTypes.object,
    userProfile: PropTypes.object,
    loading: PropTypes.bool,
    loadUserProfile: PropTypes.func,
    saveUserProfile: PropTypes.func
};

export default connect(mapStateToProps, mapDispatchToProps)(UserProfileForm);
