import axios from 'axios';

import { processLogoutRequest } from '../../auth/dispatchers';
import { userAccountDeleteFailed, userAccountDeleteInitialized, userAccountDeleteSucceeded, userAccountUpdateFailed, userAccountUpdateInitialized, userAccountUpdateSucceeded, userProfileLoadFailed, userProfileLoadInitialized, userProfileLoadSucceeded, userProfileSaveFailed, userProfileSaveInitialized, userProfileSaveSucceeded } from '../actions/userProfileActions';

export const saveUserProfile = (userProfile) => {
    return dispatch => {
        dispatch(userProfileSaveInitialized());
        const payload = { userprofile: userProfile };
        if ('id' in userProfile) {
            axios.put('/api/userprofiles/' + userProfile.id, payload)
                .then(response => {
                    if (response.data.userprofile) dispatch(userProfileSaveSucceeded(response.data.userprofile));
                    else dispatch(userProfileSaveFailed(response.data.message));
                }).catch(
                    err => dispatch(userProfileSaveFailed(!err.response ? 'Network Error' : err.response.statusText))
                );
        } else {
            axios.post('/api/userprofiles', payload)
                .then(response => {
                    if (response.data.username) dispatch(userProfileSaveSucceeded(response.data));
                    else dispatch(userProfileSaveFailed(response.data.message));
                }).catch(
                    err => dispatch(userProfileSaveFailed(!err.response ? 'Network Error' : err.response.statusText))
                );
        }
    };
};

export const loadUserProfile = (userProfile) => {
    return dispatch => {
        dispatch(userProfileLoadInitialized());
        axios.get('/api/userprofiles', userProfile)
            .then(response => {
                if (response.data.userprofiles) dispatch(userProfileLoadSucceeded(response.data.userprofiles[0]));
                else dispatch(userProfileLoadFailed(response.data.message));
            }).catch(err =>
                dispatch(userProfileLoadFailed(!err.response ? 'Network Error' : err.response.statusText))
            );
    };
};

export const deleteUserAccount = () => {
    return dispatch => {
        dispatch(userAccountDeleteInitialized());
        axios.delete('/api/account/delete')
            .then(() => {
                dispatch(userAccountDeleteSucceeded());
                // logout user since that account is already deleted
                dispatch(processLogoutRequest());
            }).catch(
                err => dispatch(userAccountDeleteFailed(!err.response ? 'Network Error' : err.response.statusText))
            );
    };
};

export const disableWelcomeScreen = () => {
    return dispatch => {
        dispatch(userAccountUpdateInitialized());
        axios.post('/api/account/update', { show_welcome: false })
            .then(response => {
                dispatch(userAccountUpdateSucceeded());
            }).catch(
                err => dispatch(userAccountUpdateFailed(!err.response ? 'NetworkError' : err.response.error))
            );
    };
};
