import axios from 'axios';

import { acceptEulaFailed, AcceptEulaInitialized, acceptEulaSucceeded, fetchModalContentFailed, fetchModalContentInitialized, fetchModalContentSucceeded, fetchSoftwareFailed, fetchSoftwareInitialized, fetchSoftwareSucceeded } from '../actions/actionCreators';

export const processFetchSoftwareRequest = () => {
    return dispatch => {
        dispatch(fetchSoftwareInitialized());

        const downloadGroupsRequest = axios.get('/api/downloadGroups');
        const productsRequest = axios.get('/api/products');

        axios.all([downloadGroupsRequest, productsRequest])
            .then(axios.spread((...responses) => {
                const downloadGroups = responses[0].data.downloadGroups;
                const products = responses[1].data.products;
                if (products && downloadGroups) {
                    dispatch(fetchSoftwareSucceeded({ products, downloadGroups }));
                } else {
                    const errors = [responses[0].data, responses[1].data];
                    dispatch(fetchSoftwareFailed(errors.join(',')));
                }
            }))
            .catch(
                error => {
                    dispatch(fetchSoftwareFailed(!error.response ? 'NetworkError' : `Error ${error.response.status}. Please contact support for help.`));
                }
            );
    };
};

export const processFetchModalContentRequest = (id) => {
    return dispatch => {
        dispatch(fetchModalContentInitialized());

        axios.get(`/api/eula/${id}`)
            .then(response => {
                if (response) dispatch(fetchModalContentSucceeded(response.data));
                else {
                    dispatch(fetchModalContentFailed(response.data));
                }
            })
            .catch(
                err => {
                    dispatch(fetchModalContentFailed(!err.response ? 'NetworkError' : err.response.statusText));
                }
            );
    };
};

export const processEulaAcceptanceRequest = (productSpecs) => {
    return dispatch => {
        dispatch(AcceptEulaInitialized());

        axios.post('/api/accept_eula', productSpecs)
            .then(response => {
                if (response.status === 200) dispatch(acceptEulaSucceeded(productSpecs.id));
                else {
                    dispatch(acceptEulaFailed(response.reason));
                }
            })
            .catch(
                err => {
                    dispatch(acceptEulaFailed(!err.response ? 'NetworkError' : err.response.statusText));
                }
            );
    };
};
