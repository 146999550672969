export const PROFILE_LOAD_INIT = 'PROFILE_LOAD_INIT';
export const PROFILE_LOAD_DONE = 'PROFILE_LOAD_DONE';
export const PROFILE_LOAD_FAIL = 'PROFILE_LOAD_FAIL';
export const PROFILE_SAVE_INIT = 'PROFILE_SAVE_INIT';
export const PROFILE_SAVE_DONE = 'PROFILE_SAVE_DONE';
export const PROFILE_SAVE_FAIL = 'PROFILE_SAVE_FAIL';
export const PROFILE_DELETE_INIT = 'PROFILE_DELETE_INIT';
export const PROFILE_DELETE_DONE = 'PROFILE_DELETE_DONE';
export const PROFILE_DELETE_FAIL = 'PROFILE_DELETE_FAIL';
export const ACCOUNT_UPDATE_INIT = 'ACCOUNT_UPDATE_INIT';
export const ACCOUNT_UPDATE_DONE = 'ACCOUNT_UPDATE_DONE';
export const ACCOUNT_UPDATE_FAIL = 'ACCOUNT_UPDATE_FAIL';
