import { ACCEPT_EULA_DONE } from '../../softwareDownloads/actions/actionTypes';
import * as actionTypes from '../actions/actionTypes';

const initialState = {
    loading: false,
    orderDetails: [],
    retrieved: false
};

export default (state = initialState, action) => {
    switch (action.type) {
    case actionTypes.FETCH_ORDER_DETAILS_INIT:
        return { ...state, loading: true };
    case actionTypes.FETCH_ORDER_DETAILS_DONE:
        return { ...state, orderDetails: action.payload.order_details, loading: false, retrieved: true };
    case actionTypes.FETCH_ORDER_DETAILS_FAIL:
        return { ...state, orderDetails: [], loading: false, retrieved: false };

    case actionTypes.ACTIVATE_DEVICE_DONE: {
        // Find the appropriate order detail and append order activation to it
        const detailId = action.payload.order_activations.order_detail_id;
        const newState = { ...state, orderDetails: [...state.orderDetails] };
        newState.orderDetails.forEach(detail => {
            if (detail.id === detailId) {
                detail.order_activations = [...detail.order_activations, action.payload.order_activations];
            }
        });
        return newState;
    }

    case actionTypes.DEACTIVATE_DEVICE_DONE: {
        // Find the appropriate order detail and remove device activation from it
        const detailId = action.payload.order_detail_id;
        const deviceId = action.payload.device_id;
        const newState = { ...state, orderDetails: [...state.orderDetails] };
        newState.orderDetails.forEach(detail => {
            if (detail.id === detailId) {
                detail.order_activations = detail.order_activations.filter(activation => activation.device_id !== deviceId);
            }
        });
        return newState;
    }
    case ACCEPT_EULA_DONE: {
        const newState = { ...state, orderDetails: [...state.orderDetails] };
        newState.orderDetails.forEach(order => {
            if (order.id === action.payload) order.eula_accepted = true;
        });
        return newState;
    }
    default:
        return state;
    }
};
