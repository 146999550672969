import axios from 'axios';

import { agreementsFetchFailed, agreementsFetchInitialized, agreementsFetchSucceeded, eulaFailed, eulaSucceeded, loginFailed, loginFormReset, loginInitialized, loginSucceeded, logoutFailed, logoutInitialized, logoutSucceeded } from '../actions/authActions';

export const processLoginRequest = (credentials) => {
    return dispatch => {
        dispatch(loginInitialized());
        axios.post('/api/login', credentials)
            .then(response => {
                if (response.data.success) dispatch(loginSucceeded(response.data));
                else dispatch(loginFailed(response.data.message));
            }).catch(
                err => dispatch(loginFailed(!err.response ? 'NetworkError' : err.response.error))
            );
    };
};

export const getUserData = () => {
    return dispatch => {
        axios.get('/api/login')
            .then(response => {
                if (response.data.success) dispatch(loginSucceeded(response.data));
                else dispatch(loginFailed());
            });
    };
};

export const processLogoutRequest = () => {
    return dispatch => {
        dispatch(logoutInitialized());
        localStorage.removeItem('currentUser');
        axios.delete('/api/login')
            .then(response => {
                if (response.data.success) dispatch(logoutSucceeded(response.data));
                else dispatch(logoutFailed(response.data.message));
            }).catch(
                err => dispatch(logoutFailed(!err.response ? 'NetworkError' : err.response.error))
            );
    };
};

export const sendAgreementsToServer = (agreements) => {
    return dispatch => {
        axios.post('/api/agreements', agreements)
            .then(response => {
                if (response.data.agreement) dispatch(eulaSucceeded());
                else dispatch(eulaFailed(response.data.message));
            }).catch(
                err => dispatch(eulaFailed(!err.response ? 'NetworkError' : err.response.error))
            );
    };
};

export const fetchAgreementsFromServer = () => {
    return dispatch => {
        dispatch(agreementsFetchInitialized);
        axios.get('/api/agreements')
            .then(response => {
                if (response.data.agreements) dispatch(agreementsFetchSucceeded(response.data.agreements[0]));
                else dispatch(agreementsFetchFailed(response.data.message));
            }).catch(
                err => dispatch(agreementsFetchFailed(!err.response ? 'NetworkError' : err.response.error))
            );
    };
};

export const resetLoginForm = () => {
    return dispatch => {
        dispatch(loginFormReset());
    };
};
