import PropTypes from 'prop-types';
import React from 'react';
import { Table } from 'react-bootstrap';
import { Link } from 'react-router-dom';

export class LicenseList extends React.Component {
    calculateDate(d) {
        const date = new Date();
        date.setDate(date.getDate() + d);
        const verboseDate = d >= 0 ? `in ${d} days` : `${Math.abs(d)} days ago`;
        return `${date.toLocaleDateString()} (${verboseDate})`;
    }

    flattenData(licenses) {
        const flattenedData = [];
        licenses.forEach(license =>
            license.order_activations.forEach(order => {
                if (
                    (this.props.expiredIn && order.expires_in_days < 0 && -order.expires_in_days <= this.props.expiredIn) ||
                    (!this.props.expiredIn && order.expires_in_days >= 0)
                ) {
                    flattenedData.push({
                        swPid: license.sw_pid,
                        authCode: license.auth_code,
                        deviceId: order.device_id,
                        expiresIn: order.expires_in_days,
                        expiryDate: this.calculateDate(order.expires_in_days)
                    });
                }
            })
        );
        return flattenedData.sort((a, b) => (a.expiresIn < b.expiresIn) ? 1 : -1);
    }

    renderTableHeader() {
        return (
            <tr className="table-header">
                <th>License</th>
                <th>Auth Code</th>
                <th>Device ID</th>
                <th>Expiry date</th>
            </tr>
        );
    }

    renderTableContent(data) {
        return data.map((license, index) => (
            <tr key={index}>
                <td>{license.swPid}</td>
                <td>{license.authCode}</td>
                <td>{license.deviceId}</td>
                <td>
                    <Link to={`/orders/activations#${license.authCode}`} className={this.props.expiredIn ? 'pn-red' : 'pn-yellow'}>
                        <b>{license.expiryDate}</b>
                    </Link>
                </td>
            </tr>
        ));
    }

    render() {
        const data = this.flattenData(this.props.licenses);
        if (!data.length) return null;
        return (
            <>
                <div className='pn-section-header pn-green'>{this.props.title}</div>
                <div>
                    <p>
                        Some of your licenses are expiring. Please contact our <a href="https://www.pluribusnetworks.com/support/" target="_blank" rel="noopener noreferrer">support center</a> to extend your license.
                    </p>
                </div>
                <Table className="font-sm" hover bordered>
                    <thead>
                        { this.renderTableHeader() }
                    </thead>
                    <tbody>
                        {this.renderTableContent(data)}
                    </tbody>
                </Table>
            </>
        );
    }
}

export default LicenseList;

LicenseList.propTypes = {
    title: PropTypes.string,
    licenses: PropTypes.array,
    expiredIn: PropTypes.number
    // If 'expiredIn' param is present licenses will be filtered to only include items,
    // which 'expires_in_days' value is negative and it's absolute value is less or equal this param.
    // This way items expired within given number of days can be queried.
    // If 'expiredIn' is false/undefined only items expiring in the future (with positive 'expires_in_days' value) will be included
};
